import React from "react";
import { Container } from "react-bootstrap";
import sheets from "./ColoredBox.module.css";

interface Props {
  children?: React.ReactNode;
  className?: any;
  styles?: {};
}

const ColoredBox = ({ children, styles, className }: Props) => {
  return (
    <Container
      className={`shadow overflow-hidden ${sheets.box} ${className}`}
      style={
        styles
          ? styles
          : {
              backgroundColor: "var(--primary)",
            }
      }
    >
      {children}
    </Container>
  );
};

export default ColoredBox;
