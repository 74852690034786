import React, { useEffect, useRef, useState } from "react";
import Box from "../../components/UI/Box";
import styles from "../weightGain/WeightSecondStep.module.css";
import stylesMain from "./BloodGlucoseMain.module.css";
import { Row } from "react-bootstrap";
import Title from "../../components/UI/Title";
import { Button, ConfigProvider, Input, Select } from "antd";
import Divider from "../../components/UI/Divider";
import { useNavigate } from "react-router-dom";
import { parseArabic } from "./../healper";

const { Option } = Select;

const BloodGlucoseMain = () => {
  const [activity, setActivity] = useState(null);
  const [result, setResult] = useState(null);
  const [firstError, setFirstError] = useState(false);
  const [secondError, setSecondError] = useState(false);

  const handleChange = (value: { value: string; label: React.ReactNode }) => {
    setActivity(value);
  };
  const testResult = useRef(null);
  let navigate = useNavigate();
  const displayResults = () => {
    setFirstError(false);
    setSecondError(false);
    const data = {
      activity: activity,
      testResult: parseArabic(testResult.current.input.value),
    };
    console.log(data.activity);
    if (data.activity == null) {
      setFirstError(true);
      return;
    }
    if (data.testResult == null) {
      setSecondError(true);
      return;
    }
    getResults(data);
  };

  useEffect(() => {
    console.log(result);
    if (result != null) {
      let path = `/main/blood-glucose-test/results`;
      let subTitle = "";
      let title = "";
      if (result == 1) {
        title = "مستوى سكر الدم ممتاز";
        subTitle = "رائع! فخورة بك!";
      } else if (result == 2) {
        title = "مستوى سكر الدم مقبول";
        subTitle = "أحسنت! خطوة إيجابية للأمام!";
      } else if (result == 3) {
        title = "مستوى سكر الدم مرتفع";
        subTitle = `لا تقلقي، تواصلي معنا بشكل مباشر على الرقم في الأسفل للمساعدة في حال كان لديك أي سؤال`;
      }

      navigate(path, {
        replace: false,
        state: {
          result: result,
          subTitle: subTitle,
          title: title,
        },
      });
    }
  }, [result]);

  const getResults = async (data) => {
    console.log(data);
    setResult(5);
    switch (data.activity) {
      case 1: {
        if (data.testResult < 95) {
          setResult(1);
        } else if (data.testResult >= 95 && data.testResult <= 105) {
          setResult(2);
        } else if (data.testResult > 105) {
          setResult(3);
        }
        break;
      }
      case 2: {
        if (data.testResult <= 140) {
          console.log("done");
          setResult(2);
        } else if (data.testResult > 140) {
          setResult(3);
        }
        break;
      }
      case 3: {
        if (data.testResult <= 120) {
          setResult(1);
        } else if (data.testResult > 120) {
          setResult(3);
        }
        break;
      }
      default: {
        console.log("error ");
        break;
      }
    }
  };
  return (
    <ConfigProvider direction="rtl">
      <Box customClass={`form-box ${styles["form-box"]}`}>
        <Row className={`${styles["main-row"]}`}>
          <Title
            color={"var(--secondary)"}
            fontSize={"1.4rem"}
            className={`form-label ${styles["form-label"]}`}
          >
            متى تم اختبار فحص سكر الدم؟
          </Title>
          <Select
            defaultActiveFirstOption={true}
            style={{ width: "80%", height: "3rem" }}
            placeholder={"يرجى اختيار إجابة"}
            className={`${stylesMain.select}`}
            dropdownClassName={`dropDownStyle ${stylesMain.dropDownStyle}`}
            dropdownMatchSelectWidth={true}
            onChange={handleChange}
            status={firstError ? "error" : ""}
          >
            <Option value={1} key={1}>
              {"قبل أول وجبة"}
            </Option>
            <Option value={2} key={2}>
              {"بعد الوجبة بساعة"}
            </Option>
            <Option value={3} key={3}>
              {"بعد الوجبة بساعتين"}
            </Option>
          </Select>
        </Row>
        <Divider marginTop={"2rem"} marginBottom={"2rem"} />
        <Row className={`main-row  ${styles["main-row"]}`}>
          <Title
            color={"var(--secondary)"}
            fontSize={"1.4rem"}
            className={`form-label  ${styles["form-label"]}`}
          >
            الرجاء إدخال نتيجة الفحص
          </Title>
          <Input
            id={"weight"}
            size={"small"}
            placeholder={"95"}
            ref={testResult}
            status={secondError ? "error" : ""}
          />
        </Row>
        <Button type="primary" onClick={displayResults}>
          الناتج
        </Button>
      </Box>
    </ConfigProvider>
  );
};

export default BloodGlucoseMain;
