import React, { useEffect } from "react";
import InputGroup from "../UI/InputGroup";
import SelectGroup from "../UI/SelectGroup";
import { useLocation, useNavigate } from "react-router-dom";
import { parseArabic as PAR } from "../../pages/healper";

let resultsVal = 0;
let isValid = 0;

const CalorieForm = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const request: any = location.state;

  const displayResults = async () => {
    console.log(props.weight.current.input.value);

    const data = {
      weight: PAR(props.weight.current.input.value),
      length: PAR(props.length.current.input.value),
      age: PAR(props.age.current.input.value),
      month: PAR(props.month.current.input.value),
      babyCount: request.with,
      activity: props.activity,
    };
    console.log("end deploy");
    if (data.month > 9) {
      props.setFeedback(true);
      return;
    }
    if (
      data.weight != null &&
      data.length != null &&
      data.age != null &&
      props.activity != "" &&
      data.month != null
    ) {
      console.log("starting get data");
      console.log("isValid before");
      console.log(isValid);
      console.log(data);
      await getResults(data);
      console.log("isValid");
      console.log(isValid);
      if (isValid !== -1) {
        console.log("done");
        navigatePage();
      }
    } else {
      props.setFeedback(true);
      console.log("missing");
    }
  };

  const navigatePage = () => {
    if (isValid != null) {
      let path = `/main/calorie-needs-equations/results`;
      navigate(path, {
        replace: false,
        state: {
          title: isValid,
          result: isValid,
          pref: 'سعرة حرارية',
          subTitle:
            "هذا مقدار السعرات الحرارية المسموح لك بشكل يومي حسب عمر الحمل الحالي",
        },
      });
    }
  };

  const getResults = (data) => {
    console.log("start");
    if (data) {
      const first = 10 * data.weight;
      console.log(`first value : ${first}`);
      const seconds = 6.25 * data.length;
      console.log(`second value : ${seconds}`);
      const third = 5 * data.age;
      const firstStep = first + seconds - third - 161;
      console.log(`result value : ${firstStep}`);
      let resultSum = 0;
      switch (data.activity) {
        case 1: {
          resultSum = firstStep * 1.15;
          break;
        }
        case 2: {
          resultSum = firstStep * 1.32;
          break;
        }
        case 3: {
          resultSum = firstStep * 1.52;
          break;
        }
        default: {
          console.log("error " + data.month);
          break;
        }
      }
      console.log(data);
      console.log("isvalid swith");
      switch (data.month) {
        case 1:
        case 2:
        case 3: {
          console.log("isvalid 3");
          isValid =
            data.babyCount === 1
              ? resultSum
              : data.babyCount === 2
              ? resultSum + 200
              : -1;
          console.log(isValid);
          break;
        }
        case 4:
        case 5:
        case 6: {
          isValid =
            data.babyCount == 1
              ? resultSum + 340
              : data.babyCount == 2
              ? resultSum + 490
              : -1;

          break;
        }
        case 7:
        case 8:
        case 9: {
          isValid =
            data.babyCount == 1
              ? resultSum + 452
              : data.babyCount == 2
              ? resultSum + 602
              : -1;
          break;
        }
        default: {
          console.log("error " + data.month);
          break;
        }
      }
      console.log(`
        ${resultSum} +
          ${
            data.babyCount === 1
              ? resultSum
              : data.babyCount === 2
              ? resultSum + 200
              : -1
          }
      `);
    } else {
      console.log("error");
    }
    isValid = ~~isValid;
  };
  return (
    <>
      <form>
        <InputGroup
          current={props.current}
          title={"الرجاء إدخال وزنك قبل الحمل"}
          placeholder={"60 كغ"}
          next={props.next}
          sort={0}
          className={"roundedInput"}
          refProp={props.weight}
        />
        <InputGroup
          current={props.current}
          title={"الرجاء إدخال الطول في سنتيمتر"}
          placeholder={"160 سنتيمتر"}
          next={props.next}
          prev={props.prev}
          sort={1}
          refProp={props.length}
        />
        <InputGroup
          current={props.current}
          title={"الرجاء إدخال العمر"}
          placeholder={"35"}
          next={props.next}
          prev={props.prev}
          sort={2}
          refProp={props.age}
        />
        {/*
          <InputGroup
          current={props.current}
          title={"النشاطات الحركية"}
          placeholder={"الرجاء اختيار إجابة"}
          next={props.next}
          prev={props.prev}
          sort={3}
        />
*/}
        <SelectGroup
          current={props.current}
          title={"النشاط الحركي"}
          placeholder={"الرجاء اختيار إجابة"}
          next={props.next}
          prev={props.prev}
          sort={3}
          refProp={props.activity}
          handleChange={props.handleChange}
          options={[
            {
              value: 1,
              title: "حركة خفيفة معتمدة على النشاط اليومي والأعمال المنزلية.",
            },
            {
              value: 2,
              title: "ملتزمة بالرياضة من مرة إلى ٣ مرات في الأسبوع.",
            },
            {
              value: 3,
              title: "ملتزمة بالرياضة ٥ مرات فأكثر في الأسبوع.",
            },
          ]}
        />
        <InputGroup
          current={props.current}
          title={"عمر الحمل (الشهر)"}
          placeholder={"9 <- 1"}
          prev={props.prev}
          next={displayResults}
          sort={4}
          result={true}
          refProp={props.month}
          min={1}
          max={9}
        />
      </form>
    </>
  );
};

export default CalorieForm;
