import React from "react";
import ColoredBox from "../components/UI/ColoredBox";
import { Col, Container, Row } from "react-bootstrap";
import Title from "../components/UI/Title";
import styles from "./Main.module.css";
import { Link } from "react-router-dom";
import "./../components/UI/style.css";

const Main = () => {
  return (
    <Container>
      <h2 className={styles.h2}>الرجاء اختيار العملية الحسابية المطلوبة</h2>
      <Row>
        <Col style={{ textAlign: "left" }} className={styles.col}>
          <Link to={"/main/recommended-weight-gain/"}>
            <ColoredBox
              styles={{
                backgroundColor: "var(--primary)",
              }}
              className={`${styles.coloredBox} ${styles.mgL}`}
            >
              <img src="/images/mother.png" className={styles.img} />
              <Title
                isParagraph={false}
                color={"white"}
                className={styles.title}
              >
                حاسبة زيادة الوزن الموصى بها (طوال الـ٩ أشهر كاملةً)
              </Title>
            </ColoredBox>
          </Link>
        </Col>
        <Col className={styles.col}>
          <Link to={"/main/calorie-needs-equations/step-1"}>
            <ColoredBox
              styles={{
                backgroundColor: "var(--secondary)",
              }}
              className={`${styles.coloredBox} ${styles.mgR}`}
            >
              <img src="/images/kcal.png" className={styles.img} />
              <Title
                isParagraph={false}
                color={"white"}
                className={styles.title}
              >
                حاسبة السعرات الحرارية اللازمة (في اليوم)
              </Title>
            </ColoredBox>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "left" }} className={styles.col}>
          <Link to={"/main/blood-glucose-test/"}>
            <ColoredBox
              styles={{
                backgroundColor: "var(--secondary)",
              }}
              className={`${styles.coloredBox} ${styles.mgL}`}
            >
              <img src="/images/blood-test.png" className={styles.img} />
              <Title
                isParagraph={false}
                color={"white"}
                className={styles.title}
              >
                تقييم اختبار سكّر الدم
              </Title>
            </ColoredBox>
          </Link>
        </Col>
        <Col className={`start ${styles.col}`}>
          <Link to={"/main/portion-calculator"}>
            <ColoredBox
              styles={{
                backgroundColor: "var(--primary)",
              }}
              className={`${styles.coloredBox} ${styles.mgR}`}
            >
              <img src="/images/menu.png" className={styles.img} />
              <Title
                isParagraph={false}
                color={"white"}
                className={styles.title}
              >
                قوائم الحصص الغذائية
              </Title>
            </ColoredBox>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Main;
