export function parseArabic(str) {
  return Number(
    str
      .replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) {
        return d.charCodeAt(0) - 1632; // Convert Arabic numbers
      })
      .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (d) {
        return d.charCodeAt(0) - 1776; // Convert Persian numbers
      })
  );
}
