import React, { useEffect } from "react";
import Box from "../components/UI/Box";
import { Col, Row } from "react-bootstrap";
import styles from "./Login.module.css";
import LoginForm from "../components/Forms/LoginForm";
import { useNavigate } from "react-router-dom";

const Login = (props) => {
  return (
    <Box>
      <Row className={`flex-grow-1 ${styles.row}`}>
        <Col className={`d-flex justify-content-md-center ${styles.loginForm}`}>
          <LoginForm
            setUserData={props.setUserData}
            loggedInHandler={props.loggedInHandler}
            loggedInState={props.loggedInState}
          />
        </Col>
        <Col className={` ${styles.pattern}`}></Col>
      </Row>
    </Box>
  );
};

export default Login;
