import React, { useState } from "react";
import styles from "./LoginForm.module.css";
import apiClient from "../../services/api";
import { useNavigate, Navigate } from "react-router-dom";
import { notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import { error } from "console";
import { useSanctum } from "react-sanctum";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const LoginForm = (props) => {
  const [email, setEmail] = useState("");
  const [isEmail, setIsEmail] = useState(true);
  const [isPassword, setIsPassword] = useState(true);
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState("");
  let navigate = useNavigate();

  const openNotification = (error) => {
    notification.open({
      message: "خطأ في تسجيل الدخول",
      description: error,
      onClick: () => {
        console.log("Notification Clicked!");
      },
    });
  };
  const { authenticated, user, signIn } = useSanctum();
  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    const emailV = email;
    const passwordV = password;
    const remember = true;

    signIn(emailV, passwordV, remember)
      .then((response) => {
        if (user[1] !== "Admin") {
          setLoading(false);
          navigate("/main", {
            replace: false,
          });
        }
        if (user[1] === "Admin") {
          setLoading(false);
          navigate("/dashboard", {
            replace: false,
          });
        }
        setLoading(false);
      })
      .catch(() => {
        openNotification("البريد الإلكتروني أو كلمة المرور خاطئة");
        setLoading(false);
      });
  };
  return (
    <form
      className={styles.form}
      onSubmit={(e) => {
        handleLogin(e);
      }}
    >
      <div className="form-floating mb-3">
        <input
          type="email"
          className={`form-control rounded-5 ${!isEmail ? "is-invalid" : ""}`}
          id="floatingInput"
          placeholder="Jon Don"
          required={true}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="floatingInput">البريد الإلكتروني</label>
      </div>
      <div className="form-floating">
        <input
          type="password"
          className={`form-control rounded-5 ${
            !isPassword ? "is-invalid" : ""
          }`}
          id="floatingPassword"
          placeholder="كلمة السر"
          required={true}
          onChange={(e) => setPassword(e.target.value)}
          minLength={8}
        />
        <label htmlFor="floatingPassword">كلمة السر</label>
      </div>

      <button type="submit" className="btn btn-primary" disabled={loading}>
        تسجيل الدخول
        {loading ? (
          <Spin
            indicator={antIcon}
            style={{ marginRight: "1rem", color: "white" }}
          />
        ) : (
          ""
        )}
      </button>
    </form>
  );
};

export default LoginForm;
