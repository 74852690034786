import React from "react";
import { Button, Row, Select } from "antd";
import Title from "./Title";
import styles from "./SelectGroup.module.css";
import "./style.css";

const { Option } = Select;

function SelectGroup(props) {
  const prevBTN = (
    <Button
      type="primary"
      onClick={() => props.prev()}
      className={styles.prevBTN}
    >
      السابق
    </Button>
  );
  const nextBTN = (
    <Button
      type="primary"
      onClick={() => props.next(props.current)}
      className={styles.nextBTN}
    >
      {props.result ? "الناتج" : "التالي"}
    </Button>
  );
  return (
    <>
      <div
        className={`step${props.sort} ${
          props.current === props.sort
            ? "active"
            : props.current > props.sort
            ? "finished"
            : "form-hide"
        }`}
      >
        <Row className={styles.row}>
          <Title
            isParagraph={false}
            color={"var(--secondary)"}
            fontSize={"1.4rem"}
            className={`calorie-step-title ${styles.title}`}
          >
            {props.title ? props.title : ""}
          </Title>
          {props.prev ? prevBTN : null}
          <Select
            defaultActiveFirstOption={true}
            style={{ width: "calc(80% - 200px)" }}
            placeholder={props.placeholder ? props.placeholder : ""}
            className={`${styles["select-style"]} ${props.className}`}
            dropdownClassName={"dropDownStyle"}
            onSelect={props.onSelect}
            onChange={props.handleChange}
          >
            {props.options.map((option) => (
              <Option value={option.value} key={option.value}>
                {option.title}
              </Option>
            ))}
          </Select>
          {props.next ? nextBTN : null}
        </Row>
      </div>
    </>
  );
}

export default SelectGroup;
