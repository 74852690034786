import { UserOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import styles from "./MainDashboard.module.css";
import React, { useEffect } from "react";
import MainUsersDashboardData from "./users/MainUsersDashboardData";
import { useSanctum } from "react-sanctum";
import { Col, Container, Row } from "react-bootstrap";

const { Content, Sider } = Layout;

const item3 = [
  {
    key: 1,
    label: "المستخدمين",
  },
  {
    key: 2,
    label: "الحاسبات",
    icon: React.createElement(UserOutlined),
    disabled: true,
    type: "group",
  },
  {
    key: 3,
    label: "Coming soon",
    icon: React.createElement(UserOutlined),
    disabled: true,
  },
  {
    key: 4,
    label: "Coming soon",
    icon: React.createElement(UserOutlined),
    disabled: true,
  },
  {
    key: 5,
    label: "Coming soon",
    icon: React.createElement(UserOutlined),
    disabled: true,
  },
  {
    key: 6,
    label: "Coming soon",
    icon: React.createElement(UserOutlined),
    disabled: true,
  },
];

const sendAlert = () => {
  alert("content 1");
};

function MainDashboard(props) {
  const { user } = useSanctum();
  if (user != null) {
    if (user[1] !== "Admin") {
      return (
        <Container>
          <Row>
            <Col>Not Allowed!</Col>{" "}
          </Row>
        </Container>
      );
    }
  }

  return (
    <Layout className={`${styles.padding0} ${styles.mainBox}`}>
      <Sider className={`site-layout-background ${styles.sider}`} width={200}>
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          style={{ height: "100%" }}
          items={item3}
        />
      </Sider>
      <Content
        style={{
          padding: "2rem",
          minHeight: 280,
          flexDirection: "column",
          justifyContent: "start",
          backgroundColor: "#EBF0F8",
          marginRight: "2rem",
          borderRadius: "2rem",
        }}
      >
        <MainUsersDashboardData />
      </Content>
    </Layout>
  );
}

export default MainDashboard;
