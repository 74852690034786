import React from "react";
import { Button, Input, Row } from "antd";
import Title from "./Title";
import { inspect } from "util";
import styles from "./InputGroup.module.css";

const InputGroup = (props) => {
  const prevBTN = (
    <Button
      type="primary"
      onClick={(e) => props.prev(e)}
      className={`prevBTN ${styles.prevBTN}`}
    >
      السابق
    </Button>
  );
  const nextBTN = (
    <Button
      type="primary"
      onClick={() => props.next(props.current)}
      className={`nextBTN ${styles.nextBTN}`}
      name={"testBTNVALUE"}
      id={"testBTNVALUE"}
    >
      {props.result ? "الناتج" : "التالي"}
    </Button>
  );
  return (
    <>
      <div
        className={`step${props.sort} ${
          props.current === props.sort
            ? "active"
            : props.current > props.sort
            ? "finished"
            : "form-hide"
        }`}
      >
        <Row>
          <Title
            isParagraph={false}
            color={"var(--secondary)"}
            fontSize={"1.4rem"}
            className={"calorie-step-title"}
          >
            {props.title ? props.title : ""}
          </Title>
          <Input.Group compact>
            {props.prev ? prevBTN : null}
            <Input
              style={{ width: "calc(80% - 200px)" }}
              id={"weight"}
              placeholder={props.placeholder ? props.placeholder : ""}
              size={"small"}
              className={props.className}
              ref={props.refProp}
              onChange={props.onchange}
              min={props.min}
              max={props.max}
              required
            />
            {props.next ? nextBTN : null}
          </Input.Group>
        </Row>
      </div>
    </>
  );
};

export default InputGroup;
