const unOrganic =
  "نصف كوب من خضروات مطبوخة أو عصير الخضار \n أو 1 كوب من الخضار النيئ (غير مطبوخ)";

const Items = [
  {
    main: "النشويات",
    children: [
      {
        sub: "الخبز",
        value: {
          exchange: "1 حصة نشويات",
          carbohydrate: " 15 جرام من الكربوهيدرات",
          protein: " 3 جرام من البروتين",
          fat: " 1 جرام من الدهون",
          calories: "80 سعرة حرارية",
        },
        children: [
          {
            name: "بيغل",
            value: "¼ بيغل كبير (30 جرام)",
          },
          {
            name: "بسكويت",
            value: " 1 قطعة  (6 سم)",
          },
          {
            name: " توست أبيض",
            value: "شريحة واحدة (30 جرام)",
          },
          {
            name: "توست الحبوب الكاملة",
            value: "شريحة واحدة (30 جرام)",
          },
          {
            name: "توست فرنسي",
            value: "شريحة واحدة (30 جرام)",
          },
          {
            name: "توست ايطالي",
            value: "شريحة واحدة (30 جرام)",
          },
          {
            name: "خبز بامبرنكل",
            value: "شريحة واحدة (30 جرام)",
          },
          {
            name: "توست الجاودار",
            value: "شريحة واحدة (30 جرامًا)",
          },
          {
            name: "خبز السوردو",
            value: "شريحة واحدة (30 جرام)",
          },
          {
            name: "خبز بالقرفة",
            value: "شريحة واحدة (30 جرام)",
          },
          {
            name: "شباتي ",
            value: "30 جرام",
          },
          {
            name: "شباتا",
            value: "30 جرام",
          },
          {
            name: "نان",
            value: "30 جرام",
          },
          {
            name: "بيتا",
            value: "(عرض 15 سم) ½ بيتا",
          },
          {
            name: "روتي",
            value: "30 جرام",
          },
          {
            name: "خبز عربي أبيض",
            value: "نصف رغيف صغير أو ربع رغيف كبير",
          },
          {
            name: "خبز عربي أسمر",
            value: "نصف رغيف صغير أو ربع رغيف كبير",
          },
          {
            name: "خبز سندويش رقيق مصنوع من القمح الكامل",
            value: "1 قطعة، بما في ذلك الجزء العلوي والسفلي (45 جرام)",
          },
          {
            name: "تاكو",
            value: "2 تاكو (عرض كل منها 13 سم)",
          },
          {
            name: "تورتيلا مصنوعة من دقيق الذرة",
            value: "1 تورتيلا صغيرة (15 سم)",
          },
          {
            name: "تورتيلا  مصنوعة من الدقيق الأبيض أو دقيق القمح الكامل",
            value:
              " تورتيلا صغيرة  (قطر ١٥ سم) أو ثلث تورتيلا كبيرة  (قطر 25 سم)",
          },
          {
            name: "خبز الذرة",
            value: "(45 جرام)",
          },
          {
            name: "مافن إنجليزي",
            value: "½ مافن",
          },
          {
            name: "خبز الهوت دوج أو خبز الهمبرغر",
            value: " ½ قطعة (22.5 جرام)",
          },
          {
            name: "بانكيك",
            value: "١ بانكيك (عرض 10 سم، وسماكة 0.5 سم)",
          },
          {
            name: "وافل",
            value: "1 وافل (عرض 10 سم)",
          },
        ],
      },
      {
        sub: "رقائق الحبوب",
        value: {
          exchange: "1 حصة نشويات",
          carbohydrate: " 15 جرام من الكربوهيدرات",
          protein: " 3 جرام من البروتين",
          fat: " 1 جرام من الدهون",
          calories: "80 سعرة حرارية",
        },

        children: [
          {
            name: "حبوب النخالة",
            value: "½ كوب",
          },
          {
            name: "حبوب الشوفان المطبوخة، دقيق الشوفان المطبوخ ",
            value: " نصف كوب",
          },
          {
            name: "جرانولا ",
            value: "ربع كوب",
          },
          {
            name: "حبوب منفوخة",
            value: "١ ونصف كوب",
          },
          {
            name: "فريكة مطبوخة",
            value: " ½ كوب",
          },
          {
            name: "موسلي",
            value: "ربع كوب",
          },
          {
            name: "قمح مبشور، سادة",
            value: " ½ كوب",
          },
          {
            name: "حبوب إفطار مغلفة بالسكر",
            value: "نصف كوب",
          },
          {
            name: "حبوب إفطار غير محلاة جاهزة للأكل",
            value: "ثلاث أرباع كوب",
          },
        ],
      },
      {
        sub: "حبوب مطبوخة",
        value: {
          exchange: "1 حصة نشويات",
          carbohydrate: " 15 جرام من الكربوهيدرات",
          protein: " 3 جرام من البروتين",
          fat: " 1 جرام من الدهون",
          calories: "80 سعرة حرارية",
        },

        children: [
          {
            name: "شعير ",
            value: "ثلث كوب",
          },
          {
            name: "نخالة الشوفان",
            value: "ربع كوب",
          },
          {
            name: "نخالة القمح",
            value: "نصف كوب",
          },
          {
            name: "برغل",
            value: "½ كوب",
          },
          {
            name: "الكسكس ",
            value: "ثلث كوب",
          },
          {
            name: "حنطة سوداء",
            value: "½ كوب",
          },
          {
            name: "دخن",
            value: "ثلث كوب",
          },
          {
            name: "باستا بيضاء (جميع الأشكال والأحجام)",
            value: "⅓ كوب",
          },
          {
            name: "باستا القمح الكامل (جميع الأشكال والأحجام)",
            value: "⅓ كوب",
          },
          {
            name: "عصيدة من دقيق الذرة",
            value: "⅓ كوب",
          },
          {
            name: "كينوا، جميع الألوان",
            value: "⅓ كوب",
          },
          {
            name: "أرز، أبيض أو بني أو أي لون آخر",
            value: "ثلث كوب",
          },
          {
            name: "تبولة",
            value: "نصف كوب",
          },
          {
            name: "جنين القمح",
            value: "3 ملاعق كبيرة",
          },
          {
            name: "أرز بري",
            value: "½ كوب",
          },
        ],
      },
      {
        sub: "الخضار النشوية",
        value: {
          exchange: "1 حصة نشويات",
          carbohydrate: " 15 جرام من الكربوهيدرات",
          protein: " 3 جرام من البروتين",
          fat: " 1 جرام من الدهون",
          calories: "80 سعرة حرارية",
        },

        children: [
          {
            name: "كاسافا ",
            value: "⅓ كوب",
          },
          {
            name: "ذرة ",
            value: "نصف كوب",
          },
          {
            name: "عرنوس ذرة",
            value: "10 سم (نصف عرنوس كبير)",
          },
          {
            name: "عصيدة ذرة",
            value: "¾ كوب",
          },
          {
            name: "خضروات مشكلة مع الذرة والبازلاء",
            value: "1 كوب",
          },
          {
            name: "الجزر الأبيض",
            value: "½ كوب",
          },
          {
            name: "البازلاء",
            value: "نصف كوب",
          },
          {
            name: "بطاطا مشوية بالقشرة",
            value: "¼ حبة كبيرة (90 جرام)",
          },
          {
            name: "بطاطا مسلوقة",
            value: "نصف كوب أو نصف حبة متوسطة (90 جرام)",
          },
          {
            name: "بطاطا مهروسة",
            value: "½ كوب",
          },
          {
            name: 'شرائح بطاطا " فرايز" (مخبوزة بالفرن)',
            value: "1 كوب (60 جرام)",
          },
          {
            name: "هريس اليقطين، معلب، بدون سكر مضاف",
            value: "¾ كوب",
          },
          {
            name: "قرع",
            value: "1 كوب",
          },
          {
            name: "يام أو بطاطا حلوة، سادة",
            value: "نصف كوب (105 جرام)",
          },
        ],
      },
      {
        sub: "مقرمشات وسناكات وكراكرز",
        value: {
          exchange: "1 حصة نشويات",
          carbohydrate: " 15 جرام من الكربوهيدرات",
          protein: " 3 جرام من البروتين",
          fat: " 1 جرام من الدهون",
          calories: "80 سعرة حرارية",
        },

        children: [
          {
            name: "غراهام كراكرز",
            value: "3 مربعات (6 سم لكل منها)",
          },
          {
            name: "كراكرز الأرز والمكسرات",
            value: "١٠ قطع",
          },
          {
            name: "كراكرز بالزبدة",
            value: " 6 قطع",
          },
          {
            name: "كراكرز مملح",
            value: " 6 قطع",
          },
          {
            name: "كراكرز القمح الكامل المخبوز",
            value: "5 قطع عادية (4 سم لكل منها) أو 10 قطع رقيقة (22.5 جرام)",
          },
          {
            name: "جرانولا بار",
            value: "1 بار (22.5 جرام)",
          },
          {
            name: "الفشار بدون دهون مضافة",
            value: "3 أكواب",
          },
          {
            name: "بريتزل",
            value: " 22.5 جرام",
          },
          {
            name: "رايس كيك",
            value: "2 قطعة (كل قطعة بعرض 10 سم)",
          },
          {
            name: " رقائق شيبس مخبوزة (بطاطس أو بيتا)",
            value: "حوالي 8 رقائق (22.5 جرام)",
          },
          {
            name: "رقائق شيبس عادي (تورتيلا ، بطاطس)",
            value: "حوالي 13 رقاقة (30 جرام)",
          },
        ],
      },
    ],
  },
  {
    main: "الفاكهة",
    children: [
      {
        sub: "الفاكهة",
        value: {
          exchange: " 1 حصة فاكهة",
          carbohydrate: "15 جرام من الكربوهيدرات",
          protein: "0 جرام من البروتين",
          fat: " 0 جرام من الدهون",
          calories: "60 سعرة حرارية",
        },

        children: [
          {
            name: "تفاح",
            value: " 1 تفاحة صغيرة (120 جرام)",
          },
          {
            name: "تفاح مجفف",
            value: " 4 قطع",
          },
          {
            name: "مشمش مجفف",
            value: "8 حبات مشمش",
          },
          {
            name: "مشمش طازج",
            value: " 4 حبات مشمش (إجمالي 165 جرام)",
          },
          {
            name: "موز",
            value: "١ موزة صغيرة، طولها حوالي 10 سم ، 120 جرام",
          },
          {
            name: "توت العليق",
            value: "1 كوب",
          },
          {
            name: "بلو بيري/ توت أزرق / توت بري",
            value: " ثلاث أرباع كوب",
          },
          {
            name: " شمام",
            value: "1 كوب مقطع",
          },
          {
            name: "الكرز",
            value: " 12 حبة كرز (105 جرام)",
          },
          {
            name: " تمر",
            value: "3 حبات تمر صغير (دجلة نور) أو ١ حبة كبيرة (مدجول - مجدول)",
          },
          {
            name: "فواكه مجففة (توت ، كرز ، توت بري ، فواكه مشكلة ، زبيب)",
            value: " 2 ملعقة كبيرة.",
          },
          {
            name: " التين المجفف",
            value: "3 حبات تين مجفف صغير",
          },
          {
            name: "تين طازج",
            value: "1 ونصف حبة كبيرة أو 2 حبة متوسط الحجم (إجمالي 105 جرام)",
          },
          {
            name: "كوكتيل فواكه",
            value: " ½ كوب",
          },
          {
            name: " جريب فروت طازج",
            value: "½ جريب فروت حجم كبير (165 جرام)",
          },
          {
            name: " العنب",
            value: "17 حبة عنب صغيرة (90 جرامًا إجمالاً)",
          },
          {
            name: "جوافة",
            value: "2 حبة جوافة صغيرة (75 جرام إجمالاً)",
          },
          {
            name: "شمام كوز العسل",
            value: "1 كوب مقطع",
          },
          {
            name: "كيوي",
            value: "نصف كوب شرائح",
          },
          {
            name: "إسكدنيا / أكلي دنيا",
            value: "¾ كوب مقطع",
          },
          {
            name: " برتقال الماندرين",
            value: " ¾ كوب",
          },
          {
            name: "مانجو",
            value: "نصف مانجو صغير (165 جرام) أو نصف كوب",
          },
          {
            name: "نكتارين",
            value: "1 نكتارين متوسط (165 جرام)",
          },
          {
            name: "برتقال",
            value: "1 برتقالة متوسطة (195 جرام)",
          },
          {
            name: "البابايا",
            value: " ½ بابايا (240 جرام) أو 1 كوب مقطع",
          },
          {
            name: "دراق طازج",
            value: "1 حبة متوسطة (180 جرام)",
          },
          {
            name: " إجاص طازج / كمثرى",
            value: " ½ حبة كبيرة (120 جرام)",
          },
          {
            name: "أناناس طازج",
            value: " ثلاث أرباع كوب",
          },
          {
            name: "خوخ مجفف (برقوق)",
            value: " 3 حبات برقوق",
          },
          {
            name: "خوخ طازج (برقوق طازج)",
            value: " 2 حبة برقوق صغيرة (إجمالي 150 جرام)",
          },
          {
            name: " بذور الرمان",
            value: " نصف كوب",
          },
          {
            name: " توت العليق / رازبيري",
            value: "1 كوب",
          },
          {
            name: "فراولة",
            value: "١ وربع كوب فراولة كاملة",
          },
          {
            name: "اليوسفي",
            value: " ١ حبة كبيرة (180 جرام)",
          },
          {
            name: " بطيخ أحمر",
            value: " 1 وربع كوب مقطع",
          },
        ],
      },
      {
        sub: "عصائر الفاكهة الطبيعية",
        value: {
          exchange: " 1 حصة فاكهة",
          carbohydrate: "15 جرام من الكربوهيدرات",
          protein: "0 جرام من البروتين",
          fat: " 0 جرام من الدهون",
          calories: "60 سعرة حرارية",
        },

        children: [
          {
            name: "عصير التفاح",
            value: " ½ كوب",
          },
          {
            name: " عصير فاكهة مشكلة",
            value: "⅓ كوب",
          },
          {
            name: " عصير عنب",
            value: " ⅓ كوب",
          },
          {
            name: " عصير جريب فروت",
            value: " ½ كوب",
          },
          {
            name: "عصير برتقال",
            value: " نصف كوب",
          },
          {
            name: "عصير أناناس",
            value: "½ كوب",
          },
          {
            name: " عصير رمان",
            value: " ½ كوب",
          },
          {
            name: "عصير البرقوق/ خوخ",
            value: " ⅓ كوب",
          },
        ],
      },
    ],
  },
  {
    main: "الحليب وبدائل الحليب",
    children: [
      {
        sub: "الحليب والزبادي",
        value: {
          exchange: " 1 حصة حليب خالي من الدسم",
          carbohydrate: "12 جرام من الكربوهيدرات",
          protein: "8 جرام من البروتين",
          fat: "0 - 3 جرام دهون",
          calories: "100 سعرة حرارية",
        },

        children: [
          {
            name: "حليب خالي الدسم",
            value: "1 كوب",
          },
          {
            name: "الحليب الخاثر / الحليب الرائب / البتر ميلك الخالي من الدسم",
            value: "1 كوب",
          },
          {
            name: "حليب اسيدوفيلوس خالي الدسم",
            value: "1 كوب",
          },
          {
            name: "حليب خالي الدسم خالي من اللاكتوز",
            value: " 1 كوب",
          },
          {
            name: "حليب قليل الدسم (1٪)",
            value: " 1 كوب",
          },
          {
            name: "الحليب الخاثر / الحليب الرائب / البتر ميلك قليل الدسم (1٪)",
            value: "1 كوب",
          },
          {
            name: "حليب اسيدوفيلوس قليل الدسم (1٪)",
            value: "1 كوب",
          },
          {
            name: "حليب قليل الدسم (1٪) خالي من اللاكتوز",
            value: "1 كوب",
          },
          {
            name: "حليب مبخر قليل الدسم (1٪)",
            value: ". ½ كوب",
          },
          {
            name: "حليب مبخر خالي الدسم",
            value: " ½ كوب",
          },
          {
            name: " زبادي خالي الدسم ، سادة أو يوناني",
            value: "ثلثي كوب (180 جرام)",
          },
          {
            name: "زبادي قليل الدسم (1٪) سادة أو يوناني",
            value: "ثلثي كوب (180 جرام)",
          },
        ],
      },
      {
        sub: "الحليب والزبادي",
        value: {
          exchange: " 1 حصة حليب قليل الدسم",
          carbohydrate: "12 جرام من الكربوهيدرات",
          protein: "8 جرام من البروتين",
          fat: "5 جرام من الدهون",
          calories: "120 سعرة حرارية",
        },

        children: [
          {
            name: "حليب قليل الدسم (2٪) ",
            value: " 1 كوب",
          },
          {
            name: "حليب  اسيدوفيلوس قليل الدسم (2٪)",
            value: " 1 كوب",
          },
          {
            name: "كيفير / كفير قليل الدسم (2٪)",
            value: " 1 كوب",
          },
          {
            name: "حليب خالي من اللاكتوز قليل الدسم (2٪)",
            value: "1 كوب",
          },
          {
            name: "زبادي سادة قليل الدسم (2٪)",
            value: "ثلثي كوب (180 جرام)",
          },
        ],
      },
      {
        sub: "الحليب والزبادي",
        value: {
          exchange: " 1 حصة حليب كامل الدسم",
          carbohydrate: "12 جرام من الكربوهيدرات",
          protein: "8 جرام من البروتين",
          fat: "8 جرام من الدهون",
          calories: "160 سعرة حرارية",
        },

        children: [
          {
            name: "حليب كامل الدسم",
            value: "1 كوب",
          },
          {
            name: "الحليب الخاثر / الحليب الرائب / البتر ميلك كامل الدسم",
            value: " 1 كوب",
          },
          {
            name: "حليب ماعز كامل الدسم",
            value: " 1 كوب",
          },
          {
            name: "حليب مبخر كامل الدسم",
            value: " ½ كوب",
          },
          {
            name: "زبادي سادة كامل الدسم",
            value: " 1 كوب (240 جرام)",
          },
        ],
      },
      {
        sub: "بدائل الحليب",
        value: {
          exchange: "1 حصة كربوهيدرات",
          carbohydrate: "15 جرام من الكربوهيدرات",
          protein: "",
          fat: "",
          calories: "70 سعرة حرارية",
        },

        children: [
          {
            name: "مشروب الأرز، حليب الأرز، سادة ، خالي من الدسم",
            value: "1 كوب",
          },
          {
            name: " شراب الأرز المنكّه، حليب الأرز المنكه، قليل الدسم",
            value: "½ كوب",
          },
        ],
      },
      {
        sub: "بدائل الحليب",
        value: {
          exchange: " ½ حصة الكربوهيدرات +  ½ حصة دهون",
          carbohydrate: "7.5 جرام من الكربوهيدرات",
          protein: "",
          fat: "2.5 جرام من الدهون",
          calories: "57 سعرة حرارية",
        },

        children: [
          {
            name: "حليب الصويا، خفيف أو قليل الدسم، سادة",
            value: " 1 كوب",
          },
        ],
      },
      {
        sub: "بدائل الحليب",
        value: {
          exchange: " ½ حصة كربوهيدرات + ١ حصة دهون",
          carbohydrate: "7.5 جرام من الكربوهيدرات",
          protein: "",
          fat: "5 جرام من الدهون",
          calories: "80 سعرة حرارية",
        },

        children: [
          {
            name: "حليب الصويا السادة",
            value: " 1 كوب",
          },
        ],
      },
    ],
  },
  {
    main: "خضروات غير نشوية",
    children: [
      {
        sub: "خضروات غير نشوية",
        value: {
          exchange: "1 حصة خضار غير نشوية",
          carbohydrate: "5 جرام من الكربوهيدرات",
          protein: "2 جرام من البروتين",
          fat: "0 جرام من الدهون",
          calories: "25 سعرة حرارية",
        },
        children: [
          {
            name: "الخرشوف",
            value: "",
          },
          {
            name: "قلب الخرشوف",
            value: "",
          },
          {
            name: "لهليون",
            value: "",
          },
          {
            name: 'ذرة صغيرة "بيبي كورن"',
            value: "",
          },
          {
            name: "براعم الفاصوليا",
            value: "",
          },
          {
            name: "الفاصوليا (الخضراء ، الشمعية ، الإيطالية ، الفاصوليا الطويلة)",
            value: "",
          },
          {
            name: "البنجر / الشمندر / الشوندر",
            value: "",
          },
          {
            name: "البروكلي",
            value: "",
          },
          {
            name: "براعم بروكسل / كرنب بروكسل ",
            value: "",
          },
          {
            name: "لملفوف (أخضر، أحمر، بوك تشوي، صيني)",
            value: "",
          },
          {
            name: "الجزر",
            value: "",
          },
          {
            name: "لقرنبيط / الزهرة",
            value: "",
          },
          {
            name: " الكرفس",
            value: "",
          },
          {
            name: "كول سلو، بدون تتبيلة",
            value: "",
          },
          {
            name: "خيار",
            value: "",
          },
          {
            name: "فجل أبيض",
            value: "لفت",
          },
          {
            name: "الباذنجان",
            value: "",
          },
          {
            name: "شمر",
            value: "",
          },
          {
            name: "كرنب",
            value: "",
          },
          {
            name: " البصل الأخضر",
            value: "",
          },
          {
            name: " كالي",
            value: "",
          },
          {
            name: "الكراث",
            value: "",
          },
          {
            name: "خضروات مشكلة (بدون خضروات نشوية أو بقوليات أو معكرونة)",
            value: "",
          },
          {
            name: "فطر طازج بأنواعه",
            value: "",
          },
          {
            name: " بامية",
            value: "",
          },
          {
            name: "البصل",
            value: "",
          },
          {
            name: " الفلفل (جميع الأصناف)",
            value: "",
          },
          {
            name: "الفجل",
            value: "",
          },
          {
            name: " مخلل الملفوف، مصفى",
            value: "",
          },
          {
            name: " السبانخ",
            value: "",
          },
          {
            name: "قرع",
            value: "",
          },
          {
            name: "كوسا",
            value: "",
          },
          {
            name: " السلق السويسري",
            value: "",
          },
          {
            name: "السلق",
            value: "",
          },
          {
            name: "طماطم",
            value: "",
          },
          {
            name: "طماطم معلبة",
            value: "",
          },
          {
            name: "صلصة الطماطم (غير محلاة)",
            value: "",
          },
          {
            name: "عصير الطماطم",
            value: "",
          },
          {
            name: "اللفت",
            value: "",
          },
          {
            name: "كستناء",
            value: "",
          },
        ],
      },
    ],
  },
  {
    main: "الكربوهيدرات",
    children: [
      {
        sub: "الكربوهيدرات",
        value: {
          exchange: "1 حصة كربوهيدرات",
          carbohydrate: "15 جرام من الكربوهيدرات",
          protein: "",
          fat: "",
          calories: "70 سعرة حرارية",
        },

        children: [
          {
            name: "عسل",
            value: "1 ملعقة كبيرة",
          },
          {
            name: "مربى",
            value: " 1 ملعقة كبيرة",
          },
        ],
      },
      {
        sub: "التوابل والصلصات",
        value: {
          exchange: "1 حصة كربوهيدرات",
          carbohydrate: "15 جرام من الكربوهيدرات",
          protein: "",
          fat: "",
          calories: "70 سعرة حرارية",
        },

        children: [
          {
            name: " صلصة الباربكيو",
            value: " 3 ملاعق كبيرة",
          },
          {
            name: "صلصة كاري",
            value: " 30 جرام",
          },
          {
            name: " صلصة هويسين",
            value: "2 ملاعق كبيرة",
          },
          {
            name: " صلصة البرقوق",
            value: "2 ملاعق كبيرة",
          },
          {
            name: "تتبيلة سلطة، منزوعة الدسم، كريمية",
            value: "3 ملاعق كبيرة",
          },
          {
            name: " صلصة (سويت آند ساور)",
            value: "3 ملاعق كبيرة",
          },
        ],
      },
    ],
  },
  {
    main: "بروتين",
    children: [
      {
        sub: "بروتين خالي من الدهون",
        value: {
          exchange: " ١ حصة من البروتين الخالي من الدهون",
          carbohydrate: "0 جرام من الكربوهيدرات",
          protein: "7 جرام من البروتين",
          fat: "2 جرام من الدهون",
          calories: "45 سعرة حرارية",
        },

        children: [
          {
            name: " لحم بقري خالي الدهون ( 10٪ دهون أقل)",
            value: "٣٠ جرام",
          },
          {
            name: " أجبان تحتوي على 3 جرامات من الدهون أو أقل لكل 30 جرام",
            value: " 30 جرام",
          },
          {
            name: "جبن مخثّر",
            value: " ربع كوب (60 جرام)",
          },
          {
            name: "جبن قريش / جبن كوتيج ",
            value: " ¼ كوب (60 جرام)",
          },
          {
            name: "ريكوتا (خالي من الدسم أو خفيف)",
            value: "ربع كوب (60 جرام)",
          },
          {
            name: ' بديل البيض "سادة"',
            value: " ¼ كوب",
          },
          {
            name: " بياض البيض",
            value: " 2 بياض بيضة",
          },
          {
            name: " الأسماك: طازجة أو مجمدة، مثل سمك القد والهلبوت والهامور والبلطي والسلطان إبراهيم والسيباس",
            value: "30 جرام",
          },
          {
            name: " سمك السلمون الطازج",
            value: "30 جرام",
          },
          {
            name: "سردين معلب",
            value: ". 2 سردين صغير",
          },
          {
            name: " تونة طازجة أو معلبة في الماء أو الزيت ومصفاة",
            value: " 30 جرام",
          },
          {
            name: " لحم جاموس، لحم  نعام ، لحم أرنب ، لحم غزال",
            value: " 30 جرام",
          },
          {
            name: "هوت دوغ تحتوي على 3 جرامات من الدهن أو أقل لكل 30 جرام",
            value: "1 هوت دوغ (52.5 جرام)",
          },
          {
            name: " لحم غنم قليل الدهن",
            value: "30 جرام",
          },
          {
            name: "كبدة",
            value: " 30 جرام",
          },
          {
            name: " محار طازج أو مجمّد",
            value: "6 محار متوسط الحجم",
          },
          {
            name: "لحم الخنزير المقدد خالي الدهن",
            value: "30 جرام",
          },
          {
            name: "لحم خنزير خالي الدهن",
            value: "30 جرام",
          },
          {
            name: "دجاج بدون جلد",
            value: "30 جرام",
          },
          {
            name: "ديك رومي بدون جلد",
            value: "30 جرام",
          },
          {
            name: "بط بدون جلد",
            value: " 30 جرام",
          },
          {
            name: "أوزة بدون جلد",
            value: " 30 جرام",
          },
          {
            name: "سجق مع 3 جرامات من الدهون أو أقل لكل 30 جرام",
            value: " 30 جرام",
          },
          {
            name: "أكولات بحرية: السلطعون، المحار، لوبستر، كراب، الأسقلوب ، الجمبري، شرمب، قريدس",
            value: "30 جرام",
          },
          {
            name: "لحم العجل",
            value: " 30 جرام",
          },
        ],
      },
      {
        sub: "بروتين متوسط الدهون (قليل الدهون)",
        value: {
          exchange: " ١ حصة من البروتين متوسط الدهون",
          carbohydrate: "0 جرام من الكربوهيدرات",
          protein: "7 جرام من البروتين",
          fat: "5 جرام من الدهون",
          calories: "75 سعرة حرارية",
        },

        children: [
          {
            name: "لحم بقري متوسط الدهن (كل الدهن المرئي منزوع) - (15٪ دهون)",
            value: " 30 جرام",
          },
          {
            name: "أجبان تحتوي على 4 إلى 7 جرامات من الدهون لكل 30 جرام",
            value: "30 جرام",
          },
          {
            name: "جبنة فيتا",
            value: " 30 جرام",
          },
          {
            name: "جبنة موزاريلا",
            value: " 30 جرام",
          },
          {
            name: "جبنة بيضاء",
            value: "٣٠ جرام",
          },
          {
            name: "جبن حلوم",
            value: " ٣٠ غ",
          },
          {
            name: "جبن مطبوخ مبستر يحتوي على 4 إلى 7 جرامات من الدهون لكل 30 جرام",
            value: "30 جرام",
          },
          {
            name: "الجبن قابل للدهن قليل الدسم",
            value: " 30 جرام",
          },
          {
            name: "جبنة الريكوتا (عادية أو منزوعة الدسم جزئياً)",
            value: "نصف كوب (60 جرام)",
          },
          {
            name: "بيض",
            value: "1 بيضة",
          },
          {
            name: "السمك: أي نوع مقلي",
            value: "30 جرام",
          },
          {
            name: "لحم الضأن/ خروف",
            value: "30 جرام",
          },
          {
            name: "لحم الخنزير",
            value: " 30 جرام",
          },
          {
            name: "دجاج بالجلد",
            value: " 30 جرام",
          },
          {
            name: "ديك رومي بالجلد",
            value: " 30 جرام",
          },
          {
            name: "حمامة بالجلد",
            value: " 30 جرام",
          },
          {
            name: "البط البري مع الجلد",
            value: " 30 جرام",
          },
          {
            name: "أوزة بالجلد",
            value: " 30 جرام",
          },
          {
            name: "دجاج مقلي",
            value: " 30 جرام",
          },
          {
            name: "سجق مع 4 إلى 7 جرامات من الدهون لكل 30 جرام ",
            value: " 30 جرام",
          },
        ],
      },
      {
        sub: "بروتين عالي الدهون",
        value: {
          exchange: " ١ حصة من البروتين عالي الدهون",
          carbohydrate: "0 جرام من الكربوهيدرات",
          protein: "7 جرام من البروتين",
          fat: "8 جرام من الدهون",
          calories: "100 سعرة حرارية",
        },

        children: [
          {
            name: "جبن أمريكي",
            value: " 30 جرام",
          },
          {
            name: "الجبن الأزرق (بلو تشيز)",
            value: " 30 جرام",
          },
          {
            name: "جبن براي",
            value: " 30 جرام",
          },
          {
            name: "جبنة شيدر",
            value: " 30 جرام",
          },
          {
            name: "جبن الماعز الصلب",
            value: " 30 جرام",
          },
          {
            name: "جبنة مونتري جاك",
            value: " 30 جرام",
          },
          {
            name: "جبن بارميزان",
            value: " 30 جرام",
          },
          {
            name: "جبن كيزو (جبن مكسيكي)",
            value: "",
          },
          {
            name: "الجبن السويسري",
            value: "",
          },
          {
            name: "لحم خنزير مقدد",
            value: "شريحتان (30 جرام كل واحدة قبل الطهي)",
          },
          {
            name: "هوت دوغ: لحم بقري أو  لحم خنزير",
            value: " 1 هوت دوغ",
          },
          {
            name: "هوت دوغ: ديك رومي أو دجاج",
            value: "1 هوت دوغ",
          },
          {
            name: " لحم خنزير كامل الدهن",
            value: " ٣٠  جرام",
          },
        ],
      },
      {
        sub: "بروتين نباتي",
        value: {
          exchange: "١ حصة نشويات + ١ حصة بروتين خالي من الدهون",
          carbohydrate: "15 جرام من الكربوهيدرات",
          protein: "10 جرام من البروتين",
          fat: "3 جرام من الدهون",
          calories: "125 سعرة حرارية",
        },

        children: [
          {
            name: "الفاصوليا (أسود، غاربانزو، كلوي، بينتو، أبيض، حمراء) ، مطبوخة أو معلبة ، مصفاة ومغسولة",
            value: "نصف كوب",
          },
          {
            name: "عدس (أي لون) مطبوخ",
            value: "½ كوب",
          },
          {
            name: "بازلاء، مطبوخة أو معلبة ، مصفاة ومغسولة",
            value: "½ كوب",
          },
        ],
      },
      {
        sub: "بروتين نباتي",
        value: {
          exchange: "١ حصة البروتين خالي من الدهون",
          carbohydrate: "0 جرام من الكربوهيدرات",
          protein: "7 جرام من البروتين",
          fat: "2 جرام من الدهون",
          calories: "45 سعرة حرارية",
        },

        children: [
          {
            name: "شرائح لحم الخنزير المقدد، مصنوعة من الصويا",
            value: " شريحتان (15 جرام)",
          },
          {
            name: "توفو، لايت",
            value: " ½ كوب (120 جرام)",
          },
          {
            name: 'لحم بقري " أساسه غير مصنوع من اللحم - أساسه نباتي"',
            value: " 30 جرام",
          },
          {
            name: 'سجق " أساسه غير مصنوع من اللحم - أساسه نباتي"',
            value: " 30 جرام",
          },
          {
            name: "هوت دوغ  مصنوع من الصويا",
            value: "1 هوت دوغ (45 جرام)",
          },
        ],
      },
      {
        sub: "بروتين نباتي",
        value: {
          exchange: " ١ حصة من البروتين متوسط الدهون",
          carbohydrate: "0 جرام من الكربوهيدرات",
          protein: "7 جرام من البروتين",
          fat: "5 جرام من الدهون",
          calories: "75 سعرة حرارية",
        },

        children: [
          {
            name: "توفو",
            value: "نصف كوب (120 جرام)",
          },
          {
            name: 'تيمبيه "tempeh" سادة غير منكّهة',
            value: "ربع كوب (45 جرام)",
          },
        ],
      },
      {
        sub: "بروتين نباتي",
        value: {
          exchange: " ١ حصة بروتينات عالية الدهون",
          carbohydrate: "0 جرام من الكربوهيدرات",
          protein: "7 جرام من البروتين",
          fat: "8 جرام من الدهون",
          calories: "100 سعرة حرارية",
        },

        children: [
          {
            name: "زبدة المكسرات القابلة للدهن: زبدة اللوز ، زبدة الكاجو ، زبدة الفول السوداني ، زبدة فول الصويا",
            value: " 1 ملعقة طعام",
          },
        ],
      },
      {
        sub: "بروتين نباتي",
        value: {
          exchange: " ½ حصة من الكربوهيدرات + 1 حصة بروتينات متوسطة الدهون",
          carbohydrate: "7.5 جرام من الكربوهيدرات",
          protein: "7 جرام من البروتين",
          fat: "5 جرام من الدهون",
          calories: "110 سعرة حرارية",
        },

        children: [
          {
            name: "ناجتس/ ناغتس الدجاج المصنوع من الصويا",
            value: "2 قطعة (45 جرام)",
          },
          {
            name: "حمص / حمص بالطحينة",
            value: " ⅓ كوب",
          },
          {
            name: "فول الصويا غير المملح",
            value: " 22.5 جرام",
          },
        ],
      },
      {
        sub: "بروتين نباتي",
        value: {
          exchange: " 1 حصة كربوهيدرات + 1 حصة بروتينات عالية الدهون",
          carbohydrate: "15 جرام من الكربوهيدرات",
          protein: "7 جرام من البروتين",
          fat: "8 جرام من الدهون",
          calories: "170 سعرة حرارية",
        },

        children: [
          {
            name: "فلافل",
            value: "3 قطع (حوالي 5 سم عرض كل منها)",
          },
        ],
      },
      {
        sub: "بروتين نباتي",
        value: {
          exchange: " ½ حصة كربوهيدرات + 2 حصة بروتينات خالية الدهون",
          carbohydrate: "7.5 جرام من الكربوهيدرات",
          protein: "7 جرام من البروتين",
          fat: "8 جرام من الدهون",
          calories: "125 سعرة حرارية",
        },

        children: [
          {
            name: "برجر/ برغر، أساسه مصنوع من الصويا",
            value: ". 90 جرام",
          },
        ],
      },
      {
        sub: "بروتين نباتي",
        value: {
          exchange: " ½ حصة كربوهيدرات + 1 حصة بروتين خالي من الدهون",
          carbohydrate: "7.5 جرام من الكربوهيدرات",
          protein: "7 جرام من البروتين",
          fat: "2 جرام من الدهون",
          calories: "80 سعرة حرارية",
        },

        children: [
          {
            name: "برجر/ برغر مصنوع من الخضروات والنشاء",
            value: "شريحة واحدة (حوالي 75 جرام)",
          },
          {
            name: "دجاج بروتيني مصنوع من الفطر، خالي من اللحم",
            value: " 60 جرام",
          },
          {
            name: "ادامامي/ ايدامامي مع القشرة",
            value: "½ كوب",
          },
        ],
      },
    ],
  },
  {
    main: "الدهون",
    children: [
      {
        sub: "دهون غير مشبعة - دهون أحادية غير مشبعة",
        value: {
          exchange: "1 حصة دهون",
          carbohydrate: "",
          protein: "",
          fat: "5 جرام من الدهون",
          calories: "45 سعرة حرارية",
        },

        children: [
          {
            name: "حليب اللوز (غير محلى)",
            value: " 1 كوب",
          },
          {
            name: "أفوكادو",
            value: " 2 ملعقة كبيرة (30 جرام)",
          },
        ],
      },
      {
        sub: "المكسرات",
        value: {
          exchange: "1 حصة دهون",
          carbohydrate: "",
          protein: "",
          fat: "5 جرام من الدهون",
          calories: "45 سعرة حرارية",
        },

        children: [
          {
            name: "اللوز",
            value: " 6 حبات",
          },
          {
            name: " الجوز البرازيلي",
            value: " 2 حبة",
          },
          {
            name: " كاجو",
            value: " 6 حبات",
          },
          {
            name: "بندق",
            value: " 5 حبات",
          },
          {
            name: " المكاديميا",
            value: " 3 حبات",
          },
          {
            name: "مكسرات مشكلة",
            value: "6 حبات",
          },
          {
            name: "الفول السوداني",
            value: " 10 حبات",
          },
          {
            name: " بيكان / جوز أمريكي",
            value: "4 أنصاف",
          },
          {
            name: "فستق",
            value: " 16 حبة",
          },
          {
            name: "زيت الكانولا",
            value: ". 1 ملعقة صغيرة",
          },
          {
            name: " زيت الزيتون",
            value: ". 1 ملعقة صغيرة",
          },
          {
            name: "زيت الفول السوداني",
            value: " 1 ملعقة صغيرة",
          },
          {
            name: " الزيتون الأسود",
            value: " 8 حبات",
          },
          {
            name: "لزيتون الأخضر",
            value: " 10 حبات كبيرة",
          },
        ],
      },
      {
        sub: "دهون غير مشبعة - دهون متعدد اللّاتشبّع",
        value: {
          exchange: "1 حصة دهون",
          carbohydrate: "",
          protein: "",
          fat: "5 جرام من الدهون",
          calories: "45 سعرة حرارية",
        },

        children: [
          {
            name: "المارجرين / السمن: (الخالي من الدهون المتحولة)",
            value: " 1 ملعقة صغيرة",
          },
          {
            name: " مايونيز قليل الدسم",
            value: " 1 ملعقة كبيرة",
          },
          {
            name: " مايونيز عادي",
            value: "1 ملعقة صغيرة",
          },
          {
            name: " صلصة سلطة قوامها يشبه المايونيز (قليلة الدسم)",
            value: "1 ملعقة كبيرة",
          },
          {
            name: "صلصة سلطة قوامها يشبه المايونيز",
            value: " 1 ملعقة صغيرة",
          },
          {
            name: "الصنوبر",
            value: " 1 ملعقة كبيرة",
          },
          {
            name: "الجوز",
            value: "4 أنصاف",
          },
          {
            name: "زيت: الذرة ، زيت بذر القطن ، زيت بذر الكتان ، زيت فول الصويا ، زيت عباد الشمس",
            value: "1 ملعقة صغيرة",
          },
          {
            name: " تتبيلة سلطة قليلة الدسم",
            value: " ٢ ملعقة كبيرة",
          },
          {
            name: "تتبيلة سلطة",
            value: " 1 ملعقة كبيرة",
          },
        ],
      },
      {
        sub: "البذور",
        value: {
          exchange: "1 حصة دهون",
          carbohydrate: "",
          protein: "",
          fat: "5 جرام من الدهون",
          calories: "45 سعرة حرارية",
        },

        children: [
          {
            name: "بذور الكتان",
            value: " ونصف ملعقة كبيرة",
          },
          {
            name: " بذور اليقطين",
            value: "1 ملعقة كبيرة",
          },
          {
            name: "السمسم",
            value: " 1 ملعقة كبيرة",
          },
          {
            name: "بذور عباد الشمس",
            value: "1 ملعقة كبيرة",
          },
          {
            name: "طحينة / طحينية",
            value: " 2 ملاعق صغيرة",
          },
          {
            name: "بذور الشيا (أي لون)",
            value: " 1 ملعقة كبيرة",
          },
        ],
      },
      {
        sub: "الدهون المشبعة",
        value: {
          exchange: "1 حصة دهون",
          carbohydrate: "",
          protein: "",
          fat: "5 جرام من الدهون",
          calories: "45 سعرة حرارية",
        },
        children: [
          {
            name: " زبدة قليلة الدسم",
            value: " 1 ملعقة كبيرة",
          },
          {
            name: " زبدة",
            value: " 1 ملعقة صغيرة",
          },
          {
            name: " زبدة مخفوقة",
            value: " 2 ملعقة صغيرة",
          },
          {
            name: "زبدة قليلة الدسم المصنوعة من الزيت",
            value: " 1 ملعقة كبيرة",
          },
          {
            name: "زبدة  مصنوعة من الزيت",
            value: " 1 ونصف ملعقة كبيرة",
          },
          {
            name: "جوز هند مبشور",
            value: "2 ملعقة كبيرة",
          },
          {
            name: "حليب جوز الهند المخفف (قوامه سميك)",
            value: " ثلث كوب",
          },
          {
            name: "حليب جوز الهند العادي (قوامه سميك)",
            value: " 1 ونصف ملعقة كبيرة",
          },
          {
            name: "مشروب حليب جوز الهند (قوامه خفيف) غير محلى",
            value: "1 كوب",
          },
          {
            name: 'مزيج الحليب والكريمة " half and half "',
            value: " ٢ ملعقة كبيرة",
          },
          {
            name: "كريمة مكثفة / قشدة مكثّفة",
            value: "1 ملعقة كبيرة",
          },
          {
            name: " كريمة خفيفة",
            value: " 1 ½ ملعقة كبيرة",
          },
          {
            name: "كريمة مخفوقة / كريمة خفق",
            value: "2 ملعقة كبيرة",
          },
          {
            name: "جبن كريمي قليل الدسم",
            value: " (22.5 جرام)",
          },
          {
            name: "جبن كريمي",
            value: " (15 جرام)",
          },
          {
            name: "شحم الخنزير",
            value: " 1 ملعقة صغيرة",
          },
          {
            name: " زيت جوز الهند",
            value: " 1 ملعقة صغيرة",
          },
          {
            name: 'الكريمة الحامضة "سور كريم" قليلة الدسم',
            value: " 3 ملاعق كبيرة",
          },
          {
            name: ' الكريمة الحامضة "سور كريم"',
            value: "2 ملعقة طعام",
          },
        ],
      },
    ],
  },
    {
    main: "STARCH",
    children: [
      {
        sub: "Bread",
        value: {
          exchange: 'one starch exchange',
          carbohydrate: "15 grams of carbohydrate",
          protein: "3 grams of protein",
          fat: "1 gram of fat",
          calories: "80 calories",
        },
        children: [
          {
            name: "Bagel",
            value: "¼ large bagel (30 grams)",
          },
          {
            name: "Biscuit",
            value: "1 biscuit (6 cm)",
          },
          {
            name: "White Toast",
            value: "1 slice ( 30 grams )",
          },
          {
            name: "whole-grain toast",
            value: "1 slice ( 30 grams )",
          },
          {
            name: "French toast",
            value: "1 slice ( 30 grams )",
          },
          {
            name: "Italian toast",
            value: "1 slice ( 30 grams )",
          },
          {
            name: "pumpernickel loaf",
            value: "1 slice ( 30 grams )",
          },
          {
            name: "rye toast",
            value: "1 slice ( 30 grams )",
          },
          {
            name: "sourdough loaf",
            value: "1 slice ( 30 grams )",
          },
          {
            name: "unfrosted raisin loaf",
            value: "1 slice ( 30 grams )",
          },
          {
            name: "cinnamon loaf",
            value: "30 grams",
          },
          {
            name: "Chapatti ",
            value: "30 grams",
          },
          {
            name: "Ciabatta",
            value: "30 grams",
          },
          {
            name: "Naan",
            value: "30 grams",
          },
          {
            name: "Pita",
            value: "(عرض 15 سم) ½ بيتا",
          },
          {
            name: "Roti",
            value: "30 grams",
          },
          {
            name: "خبز عربي أبيض",
            value: "نصف رغيف صغير أو ربع رغيف كبير",
          },
          {
            name: "خبز عربي أسمر",
            value: "نصف رغيف صغير أو ربع رغيف كبير",
          },
          {
            name: "Sandwich flat buns, whole-wheat",
            value: "1 bun, including top and bottom ( 45 grams )",
          },
          {
            name: "Taco Shell",
            value: "2 taco shells (each 13 cm across)",
          },
          {
            name: "Tortilla, Corn",
            value: "1 small tortilla (15 cm across)",
          },
          {
            name: "Tortilla, flour (white or whole-wheat)",
            value:
              "1 small tortilla (15 cm) or 1/3 large tortilla ( 25 cm across)",
          },
          {
            name: "Cornbread",
            value: "( 45 grams )",
          },
          {
            name: "English muffin",
            value: "½ muffin",
          },
          {
            name: "Hot dog bun or hamburger bun",
            value: "½ bun ( 22.5 grams)",
          },
          {
            name: "Pancake",
            value: "1 pancake ( 10 cm across, 0.5 cm thick)",
          },
          {
            name: "Roll, Plain",
            value: "1 small roll (30 grams)",
          },
        ],
      },
      {
        sub: "Cereals",
        value: {
          exchange: "one starch exchange",
          carbohydrate: "15 grams of carbohydrate",
          protein: " 3 grams of protein",
          fat: "1 gram of fat",
          calories: "80 calories",
        },

        children: [
          {
            name: "Bran cereal",
            value: "½ cup",
          },
          {
            name: "Cooked Cereals (Oats, Oatmeal)",
            value: "½ cup",
          },
          {
            name: "Granola Cereal",
            value: "¼ cup",
          },
          {
            name: "Grits, Cooked",
            value: "½ cup",
          },
          {
            name: "Puffed Cereal",
            value: "1 ½ cups",
          },
          {
            name: "Muesli",
            value: "¼ cup",
          },
          {
            name: "Shredded Wheat, Plain",
            value: "½ cup",
          },
          {
            name: "Sugar – Coated Cereal",
            value: "½ cup",
          },
          {
            name: "Unsweetened, Ready-to-eat Cereal",
            value: "¾ cup",
          },
        ],
      },
      {
        sub: "Grains, cooked",
        value: {
          exchange: "one starch exchange",
          carbohydrate: "15 grams of carbohydrate",
          protein: "3 grams of protein",
          fat: "1 gram of fat",
          calories: "80 calories",
        },

        children: [
          {
            name: "Barley",
            value: "1/3 cup",
          },
          {
            name: "Dry Oat Bran",
            value: "¼ cup",
          },
          {
            name: "Dry Wheat Bran",
            value: "½ cup",
          },
          {
            name: "Bulgur",
            value: "½ cup",
          },
          {
            name: "Couscous",
            value: "⅓  cup",
          },
          {
            name: "Kasha",
            value: "½ cup",
          },
          {
            name: "Millet",
            value: "⅓  cup",
          },
          {
            name: "White Pasta (all shapes and sizes)",
            value: "⅓ cup",
          },
          {
            name: "whole-wheat pasta (all shapes and sizes)",
            value: "⅓ cup",
          },
          {
            name: "Polenta",
            value: "⅓ cup",
          },
          {
            name: "Quinoa, all colors",
            value: "⅓ cup",
          },
          {
            name: "Rice, white, brown and other colors and types",
            value: "⅓ cup",
          },
          {
            name: "Tabbouleh (Tabouli), prepared",
            value: "½ cup",
          },
          {
            name: "Wheat germ, dry",
            value: "3 tablespoons",
          },
          {
            name: "Wild rice",
            value: "½ cup",
          },
        ],
      },
      {
        sub: "Starchy vegetables, cooked",
        value: {
          exchange: "one starch exchange",
          carbohydrate: "15 grams of carbohydrate",
          protein: "3 grams of protein",
          fat: "1 gram of fat",
          calories: "80 calories",
        },

        children: [
          {
            name: "Breadfruit",
            value: "¼ cup",
          },
          {
            name: "Cassava or dasheen",
            value: "⅓  cup",
          },
          {
            name: "Corn",
            value: "½ cup",
          },
          {
            name: "corn on cob",
            value: "10 cm ( ½ large cob)",
          },
          {
            name: "Hominy",
            value: "¾ cup",
          },
          {
            name: "Mixed vegetables with corn and peas",
            value: "1 cup",
          },
          {
            name: "Parsnips",
            value: "½ cup",
          },
          {
            name: "Peas, green",
            value: "½ cup",
          },
          {
            name: "Plantain",
            value: "⅓ cup",
          },
          {
            name: "Baked potato with skin",
            value: "¼ large potato (90 grams)",
          },
          {
            name: "Boiled potato, all kinds",
            value: "½ cup or ½ medium potato (90 grams )",
          },
          {
            name: "Mashed potato, with milk and fat",
            value: "½ cup",
          },
          {
            name: "French fried (oven-baked)",
            value: "1 cup (60 grams)",
          },
          {
            name: "Pumpkin puree, canned, no sugar added",
            value: "¾ cup",
          },
          {
            name: "Squash, winter (acorn, butternut)",
            value: "1 cup",
          },
          {
            name: "Succotash",
            value: "½ cup",
          },
          {
            name: "Yam or sweet potato, plain",
            value: "½ cup (105 grams)",
          },
        ],
      },
      {
        sub: "Crackers and Snacks",
        value: {
          exchange: "one starch exchange",
          carbohydrate: "15 grams of carbohydrate",
          protein: "3 grams of protein",
          fat: "1 gram of fat",
          calories: "80 calories",
        },

        children: [
          {
            name: "Animal crackers",
            value: "8 crackers",
          },
          {
            name: "Crispbread crackers",
            value: "2 to 5 pieces ( 22.5 grams )",
          },
          {
            name: "Graham crackers",
            value: "3 squares (6 cm each)",
          },
          {
            name: "Nut and rice crackers",
            value: "10 crackers",
          },
          {
            name: "Oyster crackers",
            value: "20 crackers",
          },
          {
            name: "Round, butter type crackers",
            value: "6 crackers",
          },
          {
            name: "Saltine-type crackers",
            value: "6 crackers",
          },
          {
            name: "baked Whole-wheat crackers",
            value: "5 regular  (4 cm each) or 10 thins (22.5 grams)",
          },
          {
            name: "Granola bar or snack bar",
            value: "1 bar ( 22.5 grams)",
          },
          {
            name: "Matzoh, all shapes and sizes",
            value: "( 22.5 grams)",
          },
          {
            name: "Melba Toast",
            value: "4 pieces (each about 5 cm by 10 cm)",
          },
          {
            name: "No fat added popcorn",
            value: "3 cups",
          },
          {
            name: "popcorn with butter added",
            value: "3 cups",
          },
          {
            name: "Pretzels",
            value: "22.5 grams ",
          },
          {
            name: "Rice Cakes",
            value: "2 cakes (10 cm across)",
          },
          {
            name: "Baked chips (potato, pita)",
            value: "About 8 chips ( 22.5 grams )",
          },
          {
            name: "Regular chips (Tortilla, potato)",
            value: "about 13 chips ( 30 grams)",
          },
        ],
      },
    ],
  },
  {
    main: "Fruits",
    children: [
      {
        sub: "Fruits",
        value: {
          exchange: "1 fruit exchange",
          carbohydrate: "15 grams of carbohydrate",
          protein: "0 grams of protein",
          fat: "0 gram of fat",
          calories: "60 calories",
        },

        children: [
          {
            name: "Apple, unpeeled",
            value: "1 small apple (120 grams)",
          },
          {
            name: "Apples, dried",
            value: "4 rings",
          },
          {
            name: "Applesauce, unsweetened",
            value: "½ cup",
          },
          {
            name: "Canned Apricots",
            value: "½ cup",
          },
          {
            name: "Dried Apricots",
            value: "8 apricot halves",
          },
          {
            name: "Fresh Apricots",
            value: "4 apricots (165 grams  total)",
          },
          {
            name: "Banana",
            value: "1 extra small banana, about 10 cm long, 120 grams",
          },
          {
            name: " Blackberries",
            value: "1 cup",
          },
          {
            name: "Blueberries",
            value: "¾ cup",
          },
          {
            name: "Cantaloupe ",
            value: "1 cup diced",
          },
          {
            name: "Sweet, canned cherries",
            value: "½ cup",
          },
          {
            name: "Sweet, fresh Cherries",
            value: "12 cherries ( 105 grams)",
          },
          {
            name: "Dates",
            value: "3 small (deglet noor) dates or 1 large (medjool) date",
          },
          {
            name: "Dried fruits (blueberries, cherries, cranberries, mixed fruits, raisins)",
            value: "2 Tablespoons",
          },
          {
            name: "Dried Figs",
            value: "3 small figs",
          },
          {
            name: "Fresh Figs",
            value: "1 ½ large or 2 medium figs (105 grams total)",
          },
          {
            name: "Fruit cocktail",
            value: "½ cup",
          },
          {
            name: "Fresh Grapefruit",
            value: "½ large grapefruit (165 grams )",
          },
          {
            name: "Sections, canned Grapefruit",
            value: " ¾ cup",
          },
          {
            name: "Grapes",
            value: "17 small grapes (90 grams total)",
          },
          {
            name: "Guava",
            value: "2 small guava (75 grams  total)",
          },
          {
            name: "Honeydew melon",
            value: "1 cup diced",
          },
          {
            name: "Kiwi",
            value: "½ cup sliced",
          },
          {
            name: "Loquat",
            value: "¾ cup cubed",
          },
          {
            name: "Mandarin oranges, canned",
            value: "¾ cup",
          },
          {
            name: "Mango",
            value: "½ small mango (165 grams) or ½ cup",
          },
          {
            name: "Nectarine",
            value: "1 medium nectarine (165 grams)",
          },
          {
            name: "Orange",
            value: "1 medium orange (195 grams)",
          },
          {
            name: "Papaya",
            value: "½ papaya (240 grams) or 1 cup cubed",
          },
          {
            name: "Canned Peaches",
            value: "½ cup",
          },
          {
            name: "Fresh Peaches",
            value: "1 medium peach (180 grams)",
          },
          {
            name: "Canned Pears",
            value: "½ cup",
          },
          {
            name: "Fresh Pears",
            value: "½ large pear (120 grams)",
          },
          {
            name: "Canned Pineapple",
            value: "½ cup",
          },
          {
            name: "Fresh Pineapple",
            value: "¾ cup",
          },
          {
            name: "Plantain, extra-ripe (black), raw",
            value: "¼ plantain (67.5 grams)",
          },
          {
            name: "Canned Plums",
            value: "½ cup",
          },
          {
            name: "Dried (prunes)",
            value: "3 prunes",
          },
          {
            name: "Fresh Plums",
            value: "2 small plums ( 150 grams total)",
          },
          {
            name: "Pomegranate seeds (arils)",
            value: "½ cup",
          },
          {
            name: "Raspberries",
            value: "1 cup",
          },
          {
            name: "Strawberries",
            value: "1 ¼ cup whole berries",
          },
          {
            name: "Tangerine",
            value: "1 large tangerine (180 gram)",
          },
          {
            name: "Watermelon",
            value: "1 ¼ cups diced",
          },
        ],
      },
      {
        sub: "Fruit Juice",
        value: {
          exchange: "1 fruit exchange",
          carbohydrate: "15 grams of carbohydrate",
          protein: "0 grams of protein",
          fat: "0 gram of fat",
          calories: "60 calories",
        },

        children: [
          {
            name: "Apple juice/ cider",
            value: "½ Cup",
          },
          {
            name: "Fruit juice blends, 100% juice",
            value: "⅓ Cup",
          },
          {
            name: "Grape juice",
            value: " ⅓ Cup",
          },
          {
            name: "Grapefruit juice",
            value: "½ Cup",
          },
          {
            name: "Orange juice",
            value: " ½ Cup",
          },
          {
            name: "Pineapple juice",
            value: "½ Cup",
          },
          {
            name: "Pomegranate juice",
            value: "½ Cup",
          },
          {
            name: "Prune juice",
            value: "⅓ Cup",
          },
        ],
      },
    ],
  },
  {
    main: "Milk and Milk Substitutes",
    children: [
      {
        sub: "Milk and Yogurts",
        value: {
          exchange: "1 fat free milk exchange",
          carbohydrate: "12 grams of carbohydrate",
          protein: "8 grams of protein",
          fat: "0 - 3 gram of fat",
          calories: "100 calories",
        },

        children: [
          {
            name: "fat free milk",
            value: "1 Cup",
          },
          {
            name: "fat free buttermilk",
            value: "1 Cup",
          },
          {
            name: "fat free acidophilus milk",
            value: "1 Cup",
          },
          {
            name: "fat free lactose-free milk",
            value: "1 Cup",
          },
          {
            name: "low fat (1%) buttermilk",
            value: "1 Cup",
          },
          {
            name: "low fat (1%) milk",
            value: "1 Cup",
          },
          {
            name: "low fat (1%) acidophilus milk",
            value: "1 Cup",
          },
          {
            name: "low fat (1%) lactose-free milk",
            value: "1 Cup",
          },
          {
            name: "low fat (1%) evaporated Milk ",
            value: "½ Cup",
          },
          {
            name: "fat free evaporated Milk",
            value: "½ Cup",
          },
          {
            name: "fat free Yogurt, plain or Greek",
            value: "2/3 Cup (180 gram)",
          },
          {
            name: " low fat (1%) Yogurt, plain or Greek",
            value: "2/3 Cup (180 gram)",
          },
        ],
      },
      {
        sub: "Milk and Yogurts",
        value: {
          exchange: "1 reduced fat milk exchange",
          carbohydrate: "12 grams of carbohydrate",
          protein: "8 grams of protein",
          fat: "5 gram of fat",
          calories: "120 calories",
        },

        children: [
          {
            name: "Reduced- Fat (2%) Milk",
            value: "1 Cup",
          },
          {
            name: "Reduced- Fat (2%) acidophilus milk",
            value: "1 Cup",
          },
          {
            name: "Reduced- Fat (2%) kefir",
            value: "1 Cup",
          },
          {
            name: "Reduced- Fat (2%) lactose-free milk",
            value: "1 Cup",
          },
          {
            name: "Reduced- Fat (2%) Yogurt, plain",
            value: "2/3 Cup (180 gram)",
          },
        ],
      },
      {
        sub: "Milk and Yogurts",
        value: {
          exchange: " 1 whole milk exchange",
          carbohydrate: "12 grams of carbohydrate",
          protein: "8 grams of protein",
          fat: "8 gram of fat",
          calories: "160 calories",
        },

        children: [
          {
            name: "Whole Milk",
            value: "1 Cup",
          },
          {
            name: "Whole buttermilk",
            value: "1 Cup",
          },
          {
            name: "Whole goat’s milk",
            value: "1 Cup",
          },
          {
            name: "Whole Evaporated milk",
            value: "½ Cup",
          },
          {
            name: "whole yogurt, plain",
            value: "1 Cup (240 grams)",
          },
        ],
      },
      {
        sub: "Other Milk foods and milk Substitutes",
        value: {
          exchange: "1 carb exchange",
          carbohydrate: "15 grams of carbohydrate",
          protein: "",
          fat: "",
          calories: "70 calories",
        },

        children: [
          {
            name: "Rice Drink, Plain, fat-free",
            value: "1 Cup",
          },
          {
            name: "Rice Drink, Flavored, low fat",
            value: "½ Cup",
          },
        ],
      },
      {
        sub: "Other Milk foods and milk Substitutes",
        value: {
          exchange: " ½  carb exchange and ½ fat exchange",
          carbohydrate: " 7.5 grams of carbohydrate",
          protein: "",
          fat: "2.5 gram of fat",
          calories: "57 calories",
        },

        children: [
          {
            name: "Soy Milk, Light or low-fat, plain",
            value: "1 Cup",
          },
        ],
      },
      {
        sub: "Other Milk foods and milk Substitutes",
        value: {
          exchange: " ½  carb exchange and 1 fat exchange",
          carbohydrate: "7.5 grams of carbohydrate",
          protein: "",
          fat: "5 gram of fat",
          calories: "80 calories",
        },

        children: [
          {
            name: "Soy Milk, Regular, plain",
            value: "1 Cup",
          },
        ],
      },
    ],
  },
  {
    main: "Non-starchy vegetables",
    children: [
      {
        sub: "Non-starchy vegetables",
        value: {
          exchange: "1 non-starchy vegetable exchange",
          carbohydrate: "5 grams of carbohydrate",
          protein: "2 grams of protein",
          fat: "0 gram of fat",
          calories: "25 calories",
        },
        children: [
          {
            name: "Amaranth leaves ( Chinese spinach )",
            value: "",
          },
          {
            name: "Artichoke",
            value: "",
          },
          {
            name: "Artichoke heart",
            value: "",
          },
          {
            name: 'Asparagus',
            value: "",
          },
          {
            name: "Baby corn",
            value: "",
          },
          {
            name: "Bamboo shoots",
            value: "",
          },
          {
            name: "Bean sprouts ( alfalfa, mung, soybean )",
            value: "",
          },
          {
            name: "Beans ( green, wax, Italian, yard-long beans)",
            value: "",
          },
          {
            name: "Beets",
            value: "",
          },
          {
            name: "Broccoli",
            value: "",
          },
          {
            name: "Broccoli slaw, packaged, no dressing",
            value: "",
          },
          {
            name: "Brussels sprouts",
            value: "",
          },
          {
            name: "Cabbage ( Green, Red, bok choy, Chinese)",
            value: "",
          },
          {
            name: "Carrots",
            value: "",
          },
          {
            name: "Cauliflower",
            value: "",
          },
          {
            name: "Celery",
            value: "",
          },
          {
            name: "Chayote",
            value: "",
          },
          {
            name: "Coleslaw, packaged, no dressing",
            value: "",
          },
          {
            name: "Cucumber",
            value: "",
          },
          {
            name: "Daikon",
            value: "",
          },
          {
            name: "Eggplant",
            value: "",
          },
          {
            name: "Fennel",
            value: "",
          },
          {
            name: "Gourds ( bitter, bottle, luffa, bitter melon)",
            value: "",
          },
          {
            name: "Green onions or scallions",
            value: "",
          },
          {
            name: "Greens ( collard, dandelion, mustard, purslane, turnip)",
            value: "",
          },
          {
            name: "Hearts of palm",
            value: "",
          },
          {
            name: "Jicama",
            value: "",
          },
          {
            name: "Kale",
            value: "",
          },
          {
            name: "Kohlrabi",
            value: "",
          },
          {
            name: "Leeks",
            value: "",
          },
          {
            name: "Mixed vegetables (without starchy vegetables, legumes, or pasta)",
            value: "",
          },
          {
            name: "Mushrooms, all kinds, fresh",
            value: "",
          },
          {
            name: "Okra",
            value: "",
          },
          {
            name: "Onions",
            value: "",
          },
          {
            name: "Pea pods",
            value: "",
          },
          {
            name: "Peppers ( all varieties )",
            value: "",
          },
          {
            name: "Radishes",
            value: "",
          },
          {
            name: "Rutabaga",
            value: "",
          },
          {
            name: "Sauerkraut, drained and rinsed",
            value: "",
          },
          {
            name: "Spinach",
            value: "",
          },
          {
            name: "Squash, summer varieties ( yellow, patty pan, crookneck, zucchini)",
            value: "",
          },
          {
            name: "Sugar snap peas",
            value: "",
          },
          {
            name: "Swiss chard",
            value: "",
          },
          {
            name: "Tomato",
            value: "",
          },
          {
            name: "Tomatoes, canned",
            value: "",
          },
          {
            name: "Tomato sauce (unsweetened)",
            value: "",
          },
          {
            name: "Tomato/ vegetable juice",
            value: "",
          },
          {
            name: "Turnips",
            value: "",
          },
          {
            name: "Water chestnuts",
            value: "",
          },
        ],
      },
    ],
  },
  {
    main: "Carbs",
    children: [
      {
        sub: "Carbs",
        value: {
          exchange: "1 carb exchange",
          carbohydrate: "15 grams of carbohydrate",
          protein: "",
          fat: "",
          calories: "70 calories",
        },

        children: [
          {
            name: "Honey",
            value: "1 Tablespoon",
          },
          {
            name: "Jam or jelly, regular",
            value: "1 Tablespoon",
          },
        ],
      },
      {
        sub: "Condiments and Sauces",
        value: {
          exchange: "1 carb exchange",
          carbohydrate: "15 grams of carbohydrate",
          protein: "",
          fat: "",
          calories: "70 calories",
        },

        children: [
          {
            name: "Barbecue sauce",
            value: "3 Tbsp",
          },
          {
            name: "Curry sauce",
            value: "30 grams",
          },
          {
            name: "Hoisin sauce",
            value: "2 tablespoons",
          },
          {
            name: "Marinade",
            value: "2 tablespoons",
          },
          {
            name: "Plum sauce",
            value: "2 tablespoons",
          },
          {
            name: "Salad dressing, fat-free, cream-based",
            value: "3 tablespoons",
          },
          {
            name: "Sweet-and-sour sauce",
            value: "3 tablespoons",
          },
        ],
      },
    ],
  },
  {
    main: "Protein",
    children: [
      {
        sub: "Lean Protein, cooked",
        value: {
          exchange: "1 lean protein exchange",
          carbohydrate: "0 grams of carbohydrate",
          protein: "7 grams of protein",
          fat: "2 grams of fat",
          calories: "45 calories",
        },

        children: [
          {
            name: "Beef: ground (90% and higher lean OR 10% and lower fat)",
            value: " 30 grams",
          },
          {
            name: "roast (chuck, round, rump, sirloin)",
            value: " 30 grams",
          },
          {
            name: "steak (cubed, flank, porterhouse, T-bone)",
            value: " 30 grams",
          },
          {
            name: "Beef jerky",
            value: "15 grams",
          },
          {
            name: "Cheeses with 3 grams of fat or less per 30 grams",
            value: "30 grams",
          },
          {
            name: "Curd- style cheeses",
            value: "¼ Cup (60 grams)",
          },
          {
            name: "cottage- type cheese (all kinds)",
            value: "¼ Cup (60 grams)",
          },
          {
            name: 'ricotta (fat-free or light)',
            value: "¼ Cup (60 grams)",
          },
          {
            name: "Egg substitutes, plain",
            value: "¼ Cup",
          },
          {
            name: "Egg whites",
            value: "2 egg whites",
          },
          {
            name: "Fish: Fresh or frozen, such as catfish, cod, flounder, haddock, halibut, orange roughy, tilapia, trout",
            value: "30 grams",
          },
          {
            name: " Salmon, fresh or canned",
            value: "30 grams",
          },
          {
            name: "Sardines, canned",
            value: "2 small sardines",
          },
          {
            name: "Tuna, fresh or canned in water or oil and drained",
            value: "30 grams",
          },
          {
            name: "Smoked: herring or salmon",
            value: "30 grams",
          },
          {
            name: "Game: buffalo, ostrich, rabbit, venison",
            value: "30 grams",
          },
          {
            name: "Hot dog with 3 grams of fat or less per 30 grams",
            value: "1 hot dog (52.5 grams)",
          },
          {
            name: "Lamb: chop, leg, roast",
            value: "30 grams",
          },
          {
            name: "Organ meats: heart, kidney, liver",
            value: "30 grams",
          },
          {
            name: "Oysters, fresh or frozen",
            value: "6 medium oysters",
          },
          {
            name: "Canadian pork bacon, lean",
            value: "30 grams",
          },
          {
            name: "Ham",
            value: "30 grams",
          },
          {
            name: "pork, lean; Rib or loin chop/ roast, tenderloin",
            value: "30 grams",
          },
          {
            name: "chicken without skin",
            value: "30 grams",
          },
          {
            name: "turkey without skin",
            value: "30 grams",
          },
          {
            name: "Cornish hen without skin",
            value: "30 grams",
          },
          {
            name: "Domestic duck without skin",
            value: "30 grams",
          },
          {
            name: "Goose without skin",
            value: "30 grams",
          },
          {
            name: "Sausage with 3 grams of fat or less per 30 grams",
            value: "30 grams",
          },
          {
            name: "Shellfish: clams, crab, imitation, shellfish, lobster, scallops, shrimp",
            value: "30 grams",
          },
          {
            name: "Veal: cutlet (no breading), loin chop, roast",
            value: "30 grams",
          },
        ],
      },
      {
        sub: "Medium-Fat Protein, cooked",
        value: {
          exchange: "1 medium-fat protein exchange",
          carbohydrate: "0 grams of carbohydrate",
          protein: "7 grams of protein",
          fat: "5 grams of fat",
          calories: "75 calories",
        },

        children: [
          {
            name: "Beef trimmed of visible fat: ground beef ( 85% and lower lean OR 15% or higher fat), corned beef, meatloaf, prime cuts of beef (rib roast), short ribs, tongue",
            value: "30 grams",
          },
          {
            name: "Cheeses with 4 to 7 grams of fat per 30 grams",
            value: "30 grams",
          },
          {
            name: "feta cheese",
            value: "30 grams",
          },
          {
            name: "mozzarella cheese",
            value: "30 grams",
          },
          {
            name: "pasteurized processed cheese with 4 to 7 grams of fat per 30 grams",
            value: "30 grams",
          },
          {
            name: "spread, reduced- fat cheeses",
            value: "30 grams",
          },
          {
            name: "Ricotta Cheese (regular or part skim)",
            value: " ¼ Cup ( 60 grams )",
          },
          {
            name: "Egg",
            value: "1 egg",
          },
          {
            name: "Fish: any fried",
            value: "30 grams",
          },
          {
            name: "Lamb: ground, rib roast",
            value: "30 grams",
          },
          {
            name: "Pork: Cutlet, ground, shoulder roast",
            value: "30 grams",
          },
          {
            name: "chicken with skin",
            value: "30 grams",
          },
          {
            name: "turkey with skin",
            value: "30 grams",
          },
          {
            name: "dove with skin",
            value: "30 grams",
          },
          {
            name: "pheasant with skin",
            value: "30 grams",
          },
          {
            name: "wild duck with skin",
            value: "30 grams",
          },
          {
            name: "goose with skin",
            value: "30 grams",
          },
          {
            name: "fried chicken",
            value: "30 grams",
          },
          {
            name: "Sausage with 4 to 7 grams of fat per 30 grams",
            value: "30 grams",
          },
        ],
      },
      {
        sub: "High-Fat Protein, cooked",
        value: {
          exchange: "1 high-fat protein exchange",
          carbohydrate: "0 grams of carbohydrate",
          protein: "7 grams of protein",
          fat: "8 grams of fat",
          calories: "100 calories",
        },

        children: [
          {
            name: "American cheese",
            value: "30 grams",
          },
          {
            name: "blue-veined cheese",
            value: "30 grams",
          },
          {
            name: "brie cheese",
            value: "30 grams",
          },
          {
            name: "cheddar cheese",
            value: "30 grams",
          },
          {
            name: "hard goat cheese",
            value: "30 grams",
          },
          {
            name: "Monterey jack cheese",
            value: "30 grams",
          },
          {
            name: "parmesan cheese",
            value: "30 grams",
          },
          {
            name: "queso cheese",
            value: "30 grams",
          },
          {
            name: "swiss cheese",
            value: "30 grams",
          },
          {
            name: "Bacon, pork",
            value: "2 slices ( 30g each before cooking)",
          },
          {
            name: "Hot dog: beef, pork, or combination",
            value: "1 hot dog",
          },
          {
            name: "Hot dog: turkey or chicken",
            value: "1 hot dog",
          },
          {
            name: "Pork: sausage, spareribs",
            value: "30 grams",
          },
        ],
      },
      {
        sub: "Plant-based Protein",
        value: {
          exchange: "1 starch exchange + 1 lean protein exchange",
          carbohydrate: "15 grams of carbohydrate",
          protein: "10 grams of protein",
          fat: "3 gram of fat",
          calories: "125 calories",
        },

        children: [
          {
            name: " Baked beans, canned",
            value: "⅓  cup",
          },
          {
            name: "Beans (black, garbanzo, kidney, Lima, navy, pinto, white), cooked or canned, drained and rinsed",
            value: "½ cup",
          },
          {
            name: "Lentils (any color), cooked",
            value: "½ cup",
          },
          {
            name: "Peas (Black-eyed and split), cooked or canned, drained and rinsed",
            value: "½ cup",
          },
          {
            name: "Refried beans, canned ",
            value: "½ cup",
          },
        ],
      },
      {
        sub: "Plant-based Protein",
        value: {
          exchange: "one lean protein exchange",
          carbohydrate: "0 grams of carbohydrate",
          protein: "7 grams of protein",
          fat: "2 grams of fat",
          calories: "45 calories",
        },

        children: [
          {
            name: "“Bacon” strips, soy based",
            value: "2 strips (15 grams)",
          },
          {
            name: "Tofu, light",
            value: "½ cup (120 grams)",
          },
          {
            name: 'Meatless deli slices',
            value: "30 grams",
          },
          {
            name: 'meatless beef',
            value: "30 grams",
          },
          {
            name: "meatless sausage",
            value: "30 grams",
          },
          {
            name: "Hot dog, meatless, soy-based",
            value: "1 hot dog (45 grams )",
          },
        ],
      },
      {
        sub: "Plant-based Protein",
        value: {
          exchange: "one medium-fat protein exchange",
          carbohydrate: "0 grams of carbohydrate",
          protein: "7 grams of protein",
          fat: "5 grams of fat",
          calories: "75 calories",
        },

        children: [
          {
            name: "Tofu",
            value: "½ cup (120 grams)",
          },
          {
            name: "Tempeh, plain, unflavored",
            value: "¼ cup (45 grams)",
          },
          {
            name: "Sausage “breakfast- type patties”, meatless",
            value: "(45 grams)",
          },
        ],
      },
      {
        sub: "Plant-based Protein",
        value: {
          exchange: "one high-fat protein exchange",
          carbohydrate: "0 grams of carbohydrate",
          protein: "7 grams of protein",
          fat: "8 grams of fat",
          calories: "100 calories",
        },

        children: [
          {
            name: "Nut spreads: almond butter, cashew butter, peanut butter, soy nut butter",
            value: "1 Tablespoon",
          },
        ],
      },
      {
        sub: "Plant-based Protein",
        value: {
          exchange: "½ carbohydrate exchange + 1 medium-fat protein exchange",
          carbohydrate: "7.5 grams of carbohydrate",
          protein: "7 grams of protein",
          fat: "5 grams of fat",
          calories: "110 calories",
        },

        children: [
          {
            name: "chicken nuggets, soy based",
            value: "2 nuggets (45 grams)",
          },
          {
            name: "Hummus",
            value: "⅓ cup",
          },
          {
            name: "Soy nuts, unsalted",
            value: "22.5 grams",
          },
        ],
      },
      {
        sub: "Plant-based Protein",
        value: {
          exchange: "1 carbohydrate exchange + 1 high-fat protein exchange",
          carbohydrate: "15 grams of carbohydrate",
          protein: "7 grams of protein",
          fat: "8 grams of fat",
          calories: "170 calories",
        },

        children: [
          {
            name: "Falafel",
            value: "3 patties (about 5 cm across each)",
          },
        ],
      },
      {
        sub: "Plant-based Protein",
        value: {
          exchange: "½ carbohydrate exchange + 2 lean protein exchange",
          carbohydrate: "7.5 grams of carbohydrate",
          protein: "14 grams of protein",
          fat: "4 grams of fat",
          calories: "125 calories",
        },

        children: [
          {
            name: "Meatless burger, soy based",
            value: "90 grams",
          },
        ],
      },
      {
        sub: "Plant-based Protein",
        value: {
          exchange: " ½ carbohydrate exchange + 1 lean protein exchange",
          carbohydrate: "7.5 grams of carbohydrate",
          protein: "7 grams of protein",
          fat: "2 grams of fat",
          calories: "80 calories",
        },

        children: [
          {
            name: "Meatless burger, vegetable – and starch- based",
            value: "1 patty (about 75 grams)",
          },
          {
            name: "Mycoprotein chicken, meatless",
            value: "60 grams",
          },
          {
            name: "Edamame, shelled",
            value: "½ Cup",
          },
        ],
      },
    ],
  },
  {
    main: "Fats",
    children: [
      {
        sub: "Unsaturated Fats – Monounsaturated fats",
        value: {
          exchange: "1 fat exchange",
          carbohydrate: "",
          protein: "",
          fat: "5 grams of fat",
          calories: "45 calories",
        },

        children: [
          {
            name: "Almond milk ( unsweetened)",
            value: "1 Cup",
          },
          {
            name: "Avocado, medium",
            value: "2 Tbsp (30 grams )",
          },
        ],
      },
      {
        sub: "Nuts",
        value: {
          exchange: "1 fat exchange",
          carbohydrate: "",
          protein: "",
          fat: "5 grams of fat",
          calories: "45 calories",
        },

        children: [
          {
            name: "Almonds",
            value: " 6 nuts",
          },
          {
            name: "Brazil",
            value: "2 nuts",
          },
          {
            name: "Cashews",
            value: "6 nuts",
          },
          {
            name: "Filberts (hazelnuts)",
            value: "5 nuts",
          },
          {
            name: "Macadamia",
            value: "3 nuts",
          },
          {
            name: "Mixed (50% peanuts)",
            value: "6 nuts",
          },
          {
            name: "Peanuts",
            value: "10 nuts",
          },
          {
            name: "Pecans",
            value: "4 halves",
          },
          {
            name: "Pistachios",
            value: "16 nuts",
          },
          {
            name: "canola oil",
            value: "1 teaspoon",
          },
          {
            name: "olive oil",
            value: "1 teaspoon",
          },
          {
            name: "peanut oil",
            value: "1 teaspoon",
          },
          {
            name: "Black olives",
            value: "8",
          },
          {
            name: "Green olives",
            value: "10 large",
          },
          {
            name: "light Spread, plant stanol ester-type",
            value: "1 tablespoon",
          },
          {
            name: "regular Spread, plant stanol ester-type",
            value: "2 teaspoons",
          },
        ],
      },
      {
        sub: "Unsaturated Fats – Polyunsaturated fats",
        value: {
          exchange: "1 fat exchange",
          carbohydrate: "",
          protein: "",
          fat: "5 grams of fat",
          calories: "45 calories",
        },

        children: [
          {
            name: "Margarine: Lower- fat spread (30 – 50%) vegetable oil, Trans fat-free)",
            value: "1 Tablespoon",
          },
          {
            name: "Margarine: Stick, tub (trans fat-free), or squeeze ( trans fat-free)",
            value: "1 teaspoon",
          },
          {
            name: "Reduced-fat Mayonnaise",
            value: "1 tablespoon",
          },
          {
            name: "Regular Mayonnaise",
            value: "1 teaspoon",
          },
          {
            name: "Reduced fat Mayonnaise – style salad dressing",
            value: "1 tablespoon",
          },
          {
            name: "Regular Mayonnaise – style salad dressing",
            value: "1 teaspoon",
          },
          {
            name: "Pignolia (pine nuts)",
            value: "1 tablespoon",
          },
          {
            name: "Walnuts, English",
            value: " 4 halves",
          },
          {
            name: "Oil: corn, cottonseed, flaxseed, grapeseed, safflower, soybean, sunflower",
            value: "1 teaspoon",
          },
          {
            name: "Reduced-fat Salads dressing (Note: May contain carbohydrate)",
            value: "2 tablespoon",
          },
          {
            name: "Regular Salads dressing",
            value: "1 tablespoon",
          },
        ],
      },
      {
        sub: "Seeds",
        value: {
          exchange: "1 fat exchange",
          carbohydrate: "",
          protein: "",
          fat: "5 grams of fat",
          calories: "45 calories",
        },

        children: [
          {
            name: "Flaxseed, ground",
            value: "1 ½  tablespoons",
          },
          {
            name: "Pumpkin seeds",
            value: "1 tablespoons",
          },
          {
            name: "sesame seeds",
            value: "1 tablespoons",
          },
          {
            name: "sunflower seeds",
            value: "1 tablespoons",
          },
          {
            name: "Tahini or sesame paste",
            value: "2 teaspoons",
          },
          {
            name: "chia seeds",
            value: "1 tablespoons",
          },
        ],
      },
      {
        sub: "Saturated Fats",
        value: {
          exchange: "1 fat exchange",
          carbohydrate: "",
          protein: "",
          fat: "5 grams of fat",
          calories: "45 calories",
        },
        children: [
          {
            name: "Reduced-fat butter",
            value: "1 Tablespoon",
          },
          {
            name: "Stick butter",
            value: "1 teaspoon",
          },
          {
            name: "Whipped butter",
            value: "2 Teaspoon",
          },
          {
            name: "Reduced-fat or light butter blends made with oil",
            value: " 1 tablespoon",
          },
          {
            name: "Regular Butter blends made with oil",
            value: "1 ½ Tablespoon",
          },
          {
            name: "Coconut, sweetened, shredded",
            value: "2 tablespoon",
          },
          {
            name: "Light Coconut milk, canned, thick",
            value: "⅓ cup",
          },
          {
            name: "Regular Coconut milk, canned, thick",
            value: "1 ½ tablespoon",
          },
          {
            name: "Coconut milk beverage (thin), unsweetened",
            value: "1 Cup",
          },
          {
            name: 'Half-and-half',
            value: " 2 Tablespoons",
          },
          {
            name: " Heavy cream",
            value: " 1 Tablespoon",
          },
          {
            name: " Light cream",
            value: "1 ½ Tablespoon",
          },
          {
            name: "Whipped cream",
            value: "2 Tablespoon",
          },
          {
            name: "Reduced-fat Cream Cheese",
            value: "(22.5 grams)",
          },
          {
            name: "Regular Cream Cheese",
            value: "(15 grams)",
          },
          {
            name: "Lard",
            value: "1 Teaspoon",
          },
          {
            name: "Oil: Coconut, palm, palm kernel",
            value: "1 teaspoon",
          },
          {
            name: "Shortening, solid",
            value: "1 teaspoon",
          },
          {
            name: 'Reduced-fat or light sour cream',
            value: " 3 tablespoons",
          },
          {
            name: 'Regular sour cream',
            value: "2 tablespoons",
          },
        ],
      },
    ],
  },
];

export default Items;
