import React, { useContext, useEffect, useRef, useState } from "react";
import apiClient from "../../../services/api";
import { Button, Form, Input, InputRef, Modal, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import styles from "./MainUserDashboradData.module.css";
import { FormInstance } from "antd/es";
import { useNavigate } from "react-router-dom";
const EditableContext = React.createContext<FormInstance<any> | null>(null);
interface DataType {
  key: number;
  name: string;
  email: string;
  age: string;
  username: string;
  phone_number: string;
}
const data: DataType[] = [
  {
    key: null,
    name: null,
    email: null,
    age: null,
    username: null,
    phone_number: null,
  },
];

function MainUsersDashboardData() {
  let navigate = useNavigate();
  const columns: ColumnsType<DataType> = [
    {
      title: "الأسم",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "البريد الإلكتروني",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "العمر",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "رقم الموبايل",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "الاجراءات",
      key: "actions",
      dataIndex: "actions",
      render: (text, record) => (
        <>
          <Popconfirm
            title="هل أنت متأكد من عملية الحذف؟"
            onConfirm={() => deleteUserHandler(record)}
            okText={"نعم"}
            cancelText={"إلغاء"}
          >
            <a>{"حذف"}</a>
          </Popconfirm>
          <br />
          <Popconfirm
            title="هل أنت متأكد من جعل هذا المستخدم مسؤول عام؟"
            onConfirm={() => makeAdminHandler(record)}
            okText={"نعم"}
            cancelText={"إلغاء"}
          >
            <a>{"ترقية لمسؤول"}</a>
          </Popconfirm>
        </>
      ),
    },
  ];
  const deleteUserHandler = (user) => {
    apiClient.delete(`api/user/${user.id}`).then((response) => {
      console.log(response);
      getUserData();
    });
  };
  const makeAdminHandler = (user) => {
    apiClient.post(`api/user/admin-assign/${user.id}`).then((response) => {
      getUserData();
    });
  };
  const getUserData = () => {
    apiClient
      .get("api/users")
      .then((response) => {
        if (response.status === 200) {
          setUsers(response.data);
          console.log(response);
        }
        if (response.status === 403) {
          navigate("/main", {
            replace: false,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate("/main", {
            replace: false,
          });
        }
        console.log(error);
      });
  };
  const [users, setUsers] = useState(data);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    getUserData();
  }, []);

  const modelSubmitHandler = () => {
    document.forms["addUserForm"].submit();
  };
  const formSubmitHandler = () => {
    apiClient
      .post("api/user", {
        name: form.getFieldValue("name"),
        email: form.getFieldValue("email"),
        password: form.getFieldValue("password"),
        age: form.getFieldValue("age"),
        phone_number: form.getFieldValue("phone_number"),
      })
      .then((response) => {
        if (response.status === 200) {
          form.resetFields();
          getUserData();
          setModalOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [form] = Form.useForm();
  return (
    <>
      <Button
        className={styles.button}
        type="primary"
        style={{ marginBottom: 16 }}
        onClick={() => setModalOpen(true)}
      >
        اضافة مستخدم
      </Button>
      <Table
        columns={columns}
        dataSource={users}
        className={styles.table}
        pagination={{ defaultPageSize: 20 }}
      />
      <Modal
        title="اضافة مستخدم جديد"
        centered
        visible={modalOpen}
        okText={"إدخال"}
        onOk={formSubmitHandler}
        cancelText={"إلغاء"}
        onCancel={() => setModalOpen(false)}
        maskClosable={false}
      >
        <Form
          form={form}
          id={"addUserForm"}
          name="insertUserForm"
          initialValues={{ remember: true }}
          /*
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
*/

          autoComplete="off"
        >
          <Form.Item
            name="name"
            rules={[{ required: true, message: "الرجاء ادخال الأسم" }]}
          >
            <Input placeholder="اسم المستخدم" id={"name"} />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              { required: true, message: "الرجاء ادخال البريد الاكتروني" },
            ]}
          >
            <Input placeholder="البريد الإلكتروني" name={"email"} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "الرجاء ادخال كلمة السر",
              },
            ]}
          >
            <Input.Password placeholder="كلمة السر" name={"password"} />
          </Form.Item>
          <Form.Item
            name="age"
            rules={[{ required: true, message: "الرجاء ادخال عمر المستخدم" }]}
          >
            <Input placeholder="العمر" name={"age"} />
          </Form.Item>
          <Form.Item
            name="phone_number"
            rules={[
              { required: true, message: "الرجال ادخال رقم موبايل المستخدم" },
            ]}
          >
            <Input placeholder="رقم الموبايل" name={"phone_number"} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default MainUsersDashboardData;
