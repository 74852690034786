import React from "react";
import Box from "../components/UI/Box";
import { Row } from "react-bootstrap";
import Title from "../components/UI/Title";
import styles from "./Results.module.css";
import { useLocation } from "react-router-dom";
import { BarChartOutlined } from "@ant-design/icons";
import { Result } from "antd";
import Button from "../components/UI/Button";

const Results = () => {
  const result = "test done";
  const location = useLocation();
  const data: any = location.state;
  console.log(data);
  const borderColor =
    data.result == 1
      ? styles.greenBorder
      : data.result == 2
      ? styles.blueBorder
      : data.result == 3
      ? styles.redBorder
      : "";
  const iconColor =
    data.result == 1
      ? styles.green
      : data.result == 2
      ? styles.blue
      : data.result == 3
      ? styles.red
      : "";
  return (
    <Box customClass={`${styles.box} ${borderColor}`}>
      {/*
      <Row className={styles.row}>
        <Title className={styles.title}>الناتج</Title>
        <Title className={styles.title} isParagraph={true}>
          {data.result}
        </Title>
      </Row>
*/}
      <Result
        className={data.pref && "right-title-direction"}
        icon={<BarChartOutlined className={styles.icon + " " + iconColor} />}
        title={
          data.title
            ? data.title +
              " " +
              (data.pref !== undefined && data.pref ? data.pref : "")
            : "لايوجد نتيجة لعرضها"
        }
        subTitle={`${data.subTitle ? data.subTitle : ""}`}
      />
      <Title isParagraph={true} className={styles.parg}>
        هل عندك سؤال للأخصائية؟ يمكنك التواصل معنا بشكل مباشر عن طريق الواتساب:
        <a href={"https://wa.me/+97466866384"} className={styles.btnAnchot}>
          <Button className={styles.btn}>الضغط هنا</Button>
        </a>
      </Title>
    </Box>
  );
};

export default Results;
