import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/css/bootstrap.rtl.min.css";
import "./index.css";
import { HashRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { Sanctum } from "react-sanctum";

const sanctumConfig = {
  apiUrl: "https://backend.calculator.soukkara.com",
  csrfCookieRoute: "sanctum/csrf-cookie",
  signInRoute: "api/login",
  signOutRoute: "api/logout",
  userObjectRoute: "api/user",
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <HashRouter>
    <React.StrictMode>
      <ConfigProvider direction="rtl">
        <Sanctum config={sanctumConfig}>
          <App />
        </Sanctum>
      </ConfigProvider>
    </React.StrictMode>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
