import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://backend.calculator.soukkara.com",
  /*
  baseURL: "https://mahasclinic.com",
*/
  withCredentials: true,
});
export default apiClient;
