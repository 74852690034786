import React from "react";
import Pattern from "./../../svg/pattern-1.svg";

const Divider = (props) => {
  return (
    <div
      className={props.className}
      style={{
        marginTop: props.marginTop,
        marginBottom: props.marginBottom,
        flex: "0",
      }}
    >
      <img src={Pattern} width={35} />
      <img src={Pattern} width={35} />
      <img src={Pattern} width={35} />
      <img src={Pattern} width={35} />
      <img src={Pattern} width={35} />
      <img src={Pattern} width={35} />
    </div>
  );
};

export default Divider;
