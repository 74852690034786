import React, { useRef, useState } from "react";
import "antd/dist/antd.css";
import "./SecondStep.css";
import { ConfigProvider, Steps } from "antd";
import Box from "../../components/UI/Box";
import CalorieForm from "../../components/Forms/CalorieForm";
import { Container } from "react-bootstrap";

const { Step } = Steps;

const SecondStep = () => {
  const [current, setCurrent] = useState(0);
  const [error, setError] = useState(false);
  const weight = useRef(null);
  const length = useRef(null);
  const age = useRef(null);
  const month = useRef(null);
  const [result, setResult] = useState(null);
  const [activity, setActivity] = useState(null);
  const handleChange = (value: { value: string; label: React.ReactNode }) => {
    setActivity(value);
  };

  const next = (e) => {
    console.log(e);
    console.log(current);
    switch (e) {
      case 0: {
        if (weight.current.input.value == "") {
          setError(true);
          return;
        }
        break;
      }
      case 1: {
        if (length.current.input.value == "") {
          setError(true);
          return;
        }
        break;
      }
      case 2: {
        console.log("step 2: " + activity);
        if (age.current.input.value == "") {
          setError(true);
          return;
        }
        break;
      }
      case 3: {
        console.log("step 3:" + current);
        console.log(activity);
        if (activity == null) {
          setError(true);
          return;
        }
        break;
      }
      case 4: {
        console.log(month.current.input.value);
        return;
        if (
          month.current.input.value == "" ||
          month.current.input.value > "9"
        ) {
          setError(true);
          return;
        }
        break;
      }
      default: {
        break;
      }
    }
    setError(false);
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  return (
    <>
      <Container className={"parentContainer"}>
        <Steps
          current={current}
          percent={0}
          className={"steps-width full-width"}
          status={!error ? "process" : `error`}
        >
          <Step key={0} title={"0"} />
          <Step key={1} title={"1"} />
          <Step key={2} title={"2"} />
          <Step key={3} title={"3"} />
          <Step key={4} title={"4"} />
        </Steps>
        <Box className={`steps-content`} customClass={"form-box full-width"}>
          <CalorieForm
            current={current}
            next={next}
            prev={prev}
            feedback={error}
            setFeedback={setError}
            weight={weight}
            length={length}
            age={age}
            activity={activity}
            month={month}
            result={result}
            setResult={setResult}
            handleChange={handleChange}
          />
        </Box>
      </Container>
    </>
  );
};

export default SecondStep;
