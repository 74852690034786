import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ColoredBox from "../../components/UI/ColoredBox";
import styles from "./FirstStep.module.css";
import mainStyles from "./../Main.module.css";
import Title from "../../components/UI/Title";
import { Link } from "react-router-dom";

const WeightMain = () => {
  return (
    <Container>
      <h2 className={styles.h2}>
        الرجاء اختيار ما اذا كنتِ حامل بجنين واحد، أو توأم
      </h2>
      <Row className={styles.row}>
        <Col className={styles.tLeft}>
          <Link to={"/main/recommended-weight-gain/step-2"} state={{ with: 1 }}>
            <ColoredBox
              styles={{
                backgroundColor: "var(--secondary)",
              }}
              className={`${styles.coloredBox} ${mainStyles.mgL}`}
            >
              <Row>
                <Col>
                  <img src="/images/baby.png" className={mainStyles.img} />
                </Col>
              </Row>
              <Title isParagraph={false} color={"white"}>
                جنين واحد
              </Title>
            </ColoredBox>
          </Link>
        </Col>
        <Col className={styles.tRight}>
          <Link to={"/main/recommended-weight-gain/step-2"} state={{ with: 2 }}>
            <ColoredBox
              styles={{
                backgroundColor: "var(--secondary)",
              }}
              className={`${styles.coloredBox} ${mainStyles.mgR}`}
            >
              {" "}
              <Row>
                <Col className={styles.babyCol}>
                  <img src="/images/baby.png" className={mainStyles.img} />
                  <img src="/images/baby.png" className={mainStyles.img} />
                </Col>
              </Row>
              <Title isParagraph={false} color={"white"}>
                توأم 
              </Title>
            </ColoredBox>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default WeightMain;
