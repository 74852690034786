import React, { ReactNode } from "react";

interface Props {
  children?: ReactNode;
  className?: string;
  color?: string;
  isParagraph?: boolean;
  fontSize?: string;
}

const Title = ({ children, isParagraph, ...props }: Props) => {
  if (isParagraph) {
    return (
      <p className={props.className} {...props} style={{ color: props.color }}>
        {children}
      </p>
    );
  }
  return (
    <span
      className={props.className}
      {...props}
      style={{ color: props.color, fontSize: props.fontSize }}
    >
      {children}
    </span>
  );
};

export default Title;
