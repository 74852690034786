import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { COLORS } from "../values/colors";
import Title from "../components/UI/Title";
import Pattern from "./../svg/pattern-1.svg";
import Button from "../components/UI/Button";
import styles from "./Home.module.css";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <Col xs={12} sm={6}>
        <Title className={"h1"} color={COLORS.secondary}>
          أهلاً بك في عيادتك!
        </Title>
        <Title
          className={`mt-3 ${styles.parag}`}
          isParagraph={true}
          fontSize={"0.6rem"}
        >
          مها كلينك maha's clinic هي عيادة مختصة بدعم الحامل والدعم الغذائي خلال
          فترة الحمل، تم تأسيسها أونلاين لضمان وصول الفائدة إلى الأم الحامل في
          جميع أنحاء العالم؛ بإشراف مها غسّان حمّص - أخصائية تغذية طبّية مرخّصة
          ومختصة بتغذية الحوامل والحالات الطبية المرافقة.
          <br />
          <br />
          ساعدت مها كلينك مئات الحوامل خلال السنوات الماضية بالحصول على رحلة حمل
          صحية من خلال الأدوات والاستراتيجيات التي تم تطويرها..
          <br />
          و الآن، طوّرت العيادة هذه الحاسبة لتخدم المرأة الحامل المصابة بسكري
          الحمل، والتي تحتوي على أدوات تحتاجها خلال رحلتها مع سكري الحمل، لتجعل
          التعامل مع هذه الحالة الطبية أسهل وأفضل.
          <br />
          <br />
          استعمال هذه الحاسبة هو جزء من الاشتراك ببرنامج (سكّرة) البرنامج الأول
          عربياً للعلاج الغذائي لسكري الحمل إذا كنت أحد المشتركات بإمكانك الدخول
          فوراً، أو إذا أردت الاشتراكاستعمال هذه الحاسبة بإمكانك حجز موعد مكالمة
          مع فريق العيادة للاستفسار وإكمال الإجراءات
        </Title>
        <div className={"mt-5 mb-5"}>
          <img src={Pattern} width={35} />
          <img src={Pattern} width={35} />
          <img src={Pattern} width={35} />
          <img src={Pattern} width={35} />
          <img src={Pattern} width={35} />
          <img src={Pattern} width={35} />
        </div>
        <Row>
          <Col
            xs={5}
            className={`d-flex justify-content-md-end ${styles.mobile}`}
          >
            <Link to={"/login"}>
              <Button className={`btn-primary `}>تسجيل الدخول</Button>
            </Link>
          </Col>
          <Col xs={1} className={`d-flex align-items-center`} id={styles.span}>
            <Title color={"var(--secondary)"} fontSize={"1rem"}>
              أو
            </Title>
          </Col>
          <Col
            xs={5}
            className={`d-flex justify-content-md-start ps-0 ${styles.mobile}`}
          >
            <a href={"https://www.soukkara.com"} target={"_blank"}>
              <Button className={"btn-outline-primary"}>حجز موعد</Button>
            </a>
          </Col>
        </Row>
      </Col>

      <Col xs={12} sm={6}>
        <Image
          srcSet={"/images/dashboard-image.jpg"}
          width={"100%"}
          className={"shadow p-3 mb-5 bg-body rounded mt-5"}
        />
      </Col>
    </>
  );
};

export default Home;
