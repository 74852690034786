import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import Button from "../UI/Button";
import styles from "./Header.module.css";
import { Link } from "react-router-dom";
import { useSanctum } from "react-sanctum";

const Header = (props) => {
  const { user,authenticated } = useSanctum();
  return (
    <header className={styles.header}>
      <Navbar>
        <Container>
          <Navbar id="basic-navbar-nav">
            <Nav className="ms-auto">
              {user != null && user[1] === "Admin" && (
                <Link to={"/dashboard"}>
                  <Button className={`btn-primary ${styles.btn}`}>
                    اللوحة الرئيسية
                  </Button>
                </Link>
              )}
              {props.isLoggedInState ? (
                <Link to={"/main"}>
                  <Button className={`btn-primary ${styles.btn}`}>
                    العمليات الحسابية
                  </Button>
                </Link>
              ) : (
                <Link to={"/login"}>
                  <Button className={`btn-primary ${styles.btn}`}>
                    تسجيل الدخول
                  </Button>
                </Link>
              )}
            </Nav>
          </Navbar>
          <Navbar.Brand>
            {!authenticated && (
            <Link to={"/"}>
              <img
                className={styles.logo}
                src={"/images/logo.png"}
                alt={"logo"}
              />
            </Link>
            )}
            {authenticated && (
            <Link to={"/main"}>
              <img
                className={styles.logo}
                src={"/images/logo-login.png"}
                alt={"logo"}
              />
            </Link>
            )}
          </Navbar.Brand>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
