import React, { ReactNode } from "react";
import styles from "./Box.module.css";
import { Container } from "react-bootstrap";

interface Props {
  children?: ReactNode;
  customClass?: string;
  className?: string;
}

const Box = ({ children, customClass }: Props) => {
  return (
    <Container
      className={`shadow overflow-hidden ${styles.box} ${customClass}`}
    >
      {children}
    </Container>
  );
};

export default Box;
