import React, { useEffect, useState } from "react";
import Box from "../../components/UI/Box";
import { Col, Modal, Row, Select, Table } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import Items from "../../services/exchangeList";
import { Container } from "react-bootstrap";
import "./PortionCalculatorMain.css";
import Title from "../../components/UI/Title";

const { Option, OptGroup } = Select;

const PortionCalculatorMain = () => {
  const [parent, setParent] = useState("");
  const [sub, setSub] = useState({
    sub: "",
    value: {
      exchange: "",
      carbohydrate: "",
      protein: "",
      fat: "",
      calories: "",
    },
  });
  const [item, setItem] = useState({ name: "", value: "" });
  const [modelVisibility, setModelVisibility] = useState(false);
  const info = () => {
    Modal.info({
      title: `معلومات حول ${item.name}`,
      content: (
        <div>
          <p>some messages...some messages...</p>
          <p>some messages...some messages...</p>
        </div>
      ),
      onOk() {},
    });
  };
  useEffect(() => {
    console.log(Items);
  }, []);

  const selectHandler = (value) => {
    const selectedItem = value.split("-");
    setParent(Items[selectedItem[2]].main);
    setSub({
      sub: Items[selectedItem[2]].children[selectedItem[1]].sub,
      value: Items[selectedItem[2]].children[selectedItem[1]].value,
    });
    setItem({
      name: Items[selectedItem[2]].children[selectedItem[1]].children[
        selectedItem[0]
      ].name,
      value:
        Items[selectedItem[2]].children[selectedItem[1]].children[
          selectedItem[0]
        ].value,
    });
    toggleModal();
    console.log("test");
    /*
    console.log(Items[selectedItem[0]][selectedItem[1]]);
    console.log(Items[selectedItem[0]][selectedItem[1]][selectedItem[2]]);
*/
    console.log(selectedItem);
    console.log(value);
  };
  const dataSource = [
    {
      key: "1",
      name: "نوع الطعام",
      value: item.name,
    },
    {
      key: "2",
      name: "المجموعة الغذائية الرئيسية",
      value: parent,
    },
    {
      key: "3",
      name: "المجموعة الغذائية الفرعية",
      value: sub.sub,
    },
    {
      key: "4",
      name: "مقدار الحصة",
      value: item.value,
    },
    {
      key: "5",
      name: "عدد الحصص الغذائية",
      value: sub.value.exchange,
    },
    {
      key: "6",
      name: "الكربوهيدرات",
      value: sub.value.carbohydrate,
    },
    {
      key: "7",
      name: "البروتينات",
      value: sub.value.protein,
    },
    {
      key: "8",
      name: "الدهون",
      value: sub.value.fat,
    },
    {
      key: "9",
      name: "السعرات الحرارية",
      value: sub.value.calories,
    },
  ];

  const columns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "value",
      key: "value",
    },
  ];
  const toggleModal = () => {
    setModelVisibility((prevState) => !prevState);
  };
  return (
    <Box>
      <Container>
        <Row className={"selectRow"}>
          <Col className={"selectCol"}>
            <h2 className={"h2"}>ابحثي واختاري عنصر لمشاهدة معلوماته</h2>
            <Select
              notFoundContent={
                <>
                  <span>لايوجد ماتبحثين عنه!</span>
                  <br />
                  <span>
                    قد تحتاجين إلى كتابته بشكل آخر أو تبحثين عنه باللغة
                    الانجليزية لتجدين ما تبحثين عنه.
                  </span>
                  <br />
                  <span>
                    في حال أنك لم تجديه بعد ذلك, قدّمي طلبك هنا{" "}
                    <a href={"mailto:hello@mahasclinic.com"}>
                      hello@mahasclinic.com
                    </a>{" "}
                    لنضيفه لك على قاعدة البيانات المخصصة لدينا وتواصلي معنا عن
                    طريق هذا الرابط{" "}
                    <a href={"https://wa.me/+16479479290"} target={"_blank"}>
                      <LinkOutlined />
                    </a>
                  </span>
                </>
              }
              showSearch
              allowClear={true}
              className={"selectContainer"}
              style={{ width: 200 }}
              placeholder="ابحثي واختاري عنصر لمشاهدة معلوماته"
              optionFilterProp="children"
              dropdownClassName={"dropDownStyle"}
              onSelect={selectHandler}
              /*
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
*/
            >
              {Items.map((item, iIndex) =>
                item.children.map((group, gIndex) => (
                  <OptGroup label={group.sub} key={`${iIndex}${gIndex}`}>
                    {group.children.map((product, index) => (
                      <Option
                        value={`${index}-${gIndex}-${iIndex}`}
                        key={`${index}${gIndex}${iIndex}`}
                      >
                        {product.name}
                      </Option>
                    ))}
                  </OptGroup>
                ))
              )}
            </Select>
          </Col>
        </Row>
        <Modal
          title={` معلومات حول ${item.name}`}
          centered
          closable={true}
          onCancel={toggleModal}
          maskClosable={true}
          visible={modelVisibility}
          onOk={toggleModal}
          cancelText={"إغلاق"}
          okText={"إغلاق"}
          wrapClassName={"modelStyle"}
        >
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </Modal>
      </Container>
    </Box>
  );
};

export default PortionCalculatorMain;
