import React from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faTiktok,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import styles from "./Footer.module.css";
import Title from "../UI/Title";

const FaEnvelope = (
  <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
);
const FaInstagram = (
  <FontAwesomeIcon icon={faInstagram} className={styles.icon}></FontAwesomeIcon>
);
const FaTikTok = (
  <FontAwesomeIcon icon={faTiktok} className={styles.icon}></FontAwesomeIcon>
);
const FaWhatsApp = (
  <FontAwesomeIcon icon={faWhatsapp} className={styles.icon}></FontAwesomeIcon>
);
const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className={"mb-4"}>
      <Row className={styles.row}>
        <Col className={styles.phone}>
          <Title className={`${styles.span}`} color={"var(--secondary)"}>
            <a href={"tel:+97466866384"}>+974-6686-6384</a>
            هل تريدين المساعدة؟ تواصلي معنا بشكل مباشر عن طريق
          </Title>
        </Col>
        <Col className={`d-flex justify-content-md-end ${styles.socialMedia}`}>
          <a
            href={"https://instagram.com/mahas_clinic?igshid=YmMyMTA2M2Y="}
            className={styles.link}
            target={"_blank"}
          >
            {FaInstagram}
          </a>
          <a
            href={
              "https://www.tiktok.com/@mahas_clinic?is_from_webapp=1&sender_device=pc"
            }
            className={styles.link}
            target={"_blank"}
          >
            {FaTikTok}
          </a>
          <a
            href={"https://wa.me/+97466866384"}
            className={styles.link}
            target={"_blank"}
          >
            {FaWhatsApp}
          </a>
          <a href={"mailto:hello@mahasclinic.com"} className={styles.link}>
            {FaEnvelope}
          </a>
        </Col>
      </Row>
      <hr />
      <Row className={`start ${styles.copyRightFooter}`}>
        <Col className={styles.start}></Col>
        <Col className={`end ${styles.end}`}>
          <Title className={`${styles.copyright}`}>
            Copyright © {year} Maha’s Clinic. All Right Reserved
          </Title>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
