import { Container, Row } from "react-bootstrap";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Main from "./pages/Main";
import SecondStep from "./pages/calorieForm/SecondStep";
import WeightMain from "./pages/weightGain/WeightMain";
import WeightSecondStep from "./pages/weightGain/WeightSecondStep";
import FirstStep from "./pages/calorieForm/FirstStep";
import Results from "./pages/Results";
import BloodGlucoseMain from "./pages/bloodGlucose/BloodGlucoseMain";
import PortionCalculatorMain from "./pages/portionCalculator/PortionCalculatorMain";
import { useSanctum } from "react-sanctum";
import MainDashboard from "./pages/dashboard/MainDashboard";

function App() {
  const { authenticated } = useSanctum();
  return (
    <>
      <Header isLoggedInState={authenticated} />
      <main>
        <Container style={{ marginBottom: "2rem" }}>
          <Row
            className={"align-items-center ms-0 mt-5"}
            style={{ width: "100%" }}
          >
            <Routes>
              <Route path={"/"} element={<Home />} />
              <Route
                path={"/login"}
                element={
                  authenticated ? (
                    <Main />
                  ) : (
                    <Login isLoggedInState={authenticated} />
                  )
                }
              />
              <Route
                path={"/main"}
                element={
                  authenticated ? (
                    <Main />
                  ) : (
                    <Login isLoggedInState={authenticated} />
                  )
                }
              />
              <Route
                path={"/main/calorie-needs-equations/step-1"}
                element={authenticated ? <FirstStep /> : <Login />}
              />
              <Route
                path={"/main/calorie-needs-equations/step-2"}
                element={authenticated ? <SecondStep /> : <Login />}
              />
              <Route
                path={"/main/recommended-weight-gain/"}
                element={authenticated ? <WeightMain /> : <Login />}
              />
              <Route
                path={"/main/recommended-weight-gain/step-2"}
                element={authenticated ? <WeightSecondStep /> : <Login />}
              />
              <Route
                path={"/main/calorie-needs-equations/results"}
                element={authenticated ? <Results /> : <Login />}
              />
              <Route
                path={"/main/recommended-weight-gain/results"}
                element={authenticated ? <Results /> : <Login />}
              />
              <Route
                path={"/main/blood-glucose-test/"}
                element={authenticated ? <BloodGlucoseMain /> : <Login />}
              />
              <Route
                path={"/main/blood-glucose-test/results"}
                element={authenticated ? <Results /> : <Login />}
              />
              <Route
                path={"/main/portion-calculator"}
                element={authenticated ? <PortionCalculatorMain /> : <Login />}
              />
              <Route
                path={"/dashboard"}
                element={authenticated ? <MainDashboard /> : <Login />}
              />
            </Routes>
          </Row>
        </Container>
      </main>
      <Container>
        <Footer />
      </Container>
    </>
  );
}

export default App;
